import React, { Fragment, useState } from "react";
import { Button } from 'primereact/button';
import Swal from "sweetalert2";

import {
    axiosWithHeadersDownloadFile
} from '../../reducer/contains/axiosWrapper';


import * as api from '../../reducer/contains/api';

import moment from "moment";
import { Calendar } from 'primereact/calendar';
import styled from 'styled-components';
import loadingAnimation from './loading.json';
import * as DropdownList from '../../components/filter/list-dropdown';
import Lottie from 'react-lottie';

const FilterComponent = styled.section`
    margin-bottom: 10px;

    .p-dropdown .p-dropdown-trigger{
        width: 1.75rem;
    }
`;

const Index = () => {
    const [loading, setLoading] = useState(false);
    const [supermarketId, setSupermarketId] = useState("0");
    const [dateRange, setDateRange] = useState<any>(null);
    const [error, setError] = useState('');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const onDownloadFile = (e: any) => {
        e.preventDefault();
        setError('');
        // if (!supermarketId || supermarketId === "0" || supermarketId === "") {
        //     setError('Vui lòng chọn hệ thống !');
        //     return;
        // }

        if (!dateRange) {
            setLoading(true);


            axiosWithHeadersDownloadFile('POST', api.REPORT_CODE, { dateFrom:'', dateTo:'' }, "ReportCode.xlsx")
                .then((response: any) => {
                    setLoading(false);
                    // Swal.fire("Thông báo", "Tải báo cáo thành công !", "success");
                    setError('');
                }).catch(err => {
                    setLoading(false);
                    setError('');
                    Swal.fire("Lỗi", err.toString(), "error");
                });
        } else {
            let dateFrom: any = dateRange[0];
            let dateTo: any = dateRange[1];

            if (!dateFrom) {
                setError('Vui lòng chọn ngày bắt đầu !');
                return;
            }

            if (!dateTo) {
                setError('Vui lòng chọn ngày kết thúc !');
                return;
            }

            setLoading(true);

            dateFrom = moment(dateFrom).format('YYYYMMDD');
            dateTo = moment(dateTo).format('YYYYMMDD');

            axiosWithHeadersDownloadFile('POST', api.REPORT_CODE, { dateFrom, dateTo }, "ReportCode.xlsx")
                .then((response: any) => {
                    setLoading(false);
                    // Swal.fire("Thông báo", "Tải báo cáo thành công !", "success");
                    setError('');
                }).catch(err => {
                    setLoading(false);
                    setError('');
                    Swal.fire("Lỗi", err.toString(), "error");
                });
        }
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="d-flex align-items-center justify-content-between" style={{ width: `100%`, borderBottom: `1px solid #f4f4f4`, paddingBottom: `10px` }}>
                        <span style={{ fontSize: 24, fontWeight: 500 }}>Báo cáo mã dự thưởng</span>
                    </div>
                </div>
            </div>
            <FilterComponent className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <span style={{ fontSize: 18, fontWeight: 500 }}>Bộ lọc</span>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: 20 }}>
                                {/* <div className='col-md-4 col-lg-2'>
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginTop: 10 }}>
                                            <b>Hệ thống siêu thị(*)</b>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginTop: 10 }}>
                                            <DropdownList.GroupMarketFilter value={supermarketId} onChange={(e: any) => setSupermarketId(e.value)} />
                                        </div>
                                    </div>
                                </div> */}

                                <div className='col-md-4 col-lg-2' key={`from_date-1`}>
                                    <div className="row">
                                        <div className='col-md-12' style={{ marginTop: 10 }}>
                                            <span>Từ ngày - Đến ngày</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-12' style={{ marginTop: 10 }}>
                                            <Calendar
                                                style={{ width: `100%` }}
                                                value={dateRange}
                                                onChange={(e: any) => setDateRange(e.value)}
                                                selectionMode="range"
                                                dateFormat='dd/mm/yy'
                                                readOnlyInput
                                                placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                                monthNavigator
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-2' key={`action-button`}>
                                    <div className="row">
                                        <div className='col-md-12' style={{ marginTop: 10 }}>
                                            <span>&nbsp;</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-12' style={{ marginTop: 10 }}>
                                            <Button label="Tải báo cáo" className='p-button-warning' loading={loading} onClick={onDownloadFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error !== "" && (
                                <div className="row">
                                    <div className='col-md-12' style={{ marginTop: 5, color: `red` }}>
                                        {error}
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div className='row' style={{ paddingTop: 20 }}>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <Lottie options={defaultOptions}
                                            height={400}
                                            width={400}
                                            isStopped={false}
                                            isPaused={false} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </FilterComponent>
        </Fragment>
    );
}

export default Index;