import * as types from '../contains/actionTypes';

export const error_ini = () => {
    return {
        type: types.ERROR_INI
    };
};

export const error_success = (severity,summary,detail) => {
    return {
        type: types.ERROR_SUCCESS,
        severity
        ,summary
        ,detail
    }
}

