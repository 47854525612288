import * as api from "../../../reducer/contains/api";

export const listColumn = [
  {
    field: "stt",
    title: "#",
    width: 60,
  },
  {
    field: "codeLink",
    title: "Mã dự thưởng",
    width: 100,
  },
//   {
//     field: "name",
//     title: "Khách hàng",
//     width: 150,
//   },
  {
    field: "phone",
    title: "Số điện thoại",
    width: 100,
  },
  {
    field: "timeCreate",
    title: "Hình thức tham gia",
    width: 100,
  },
  {
    field: "productName",
    title: "Sản phẩm đã mua",
    width: 150,
  },
  {
    field: "dateCreate",
    title: "Ngày gửi",
    width: 100,
  },
];

export const configGrid = {
    useDateRange: true,
    api: api.LIST_BILL_ACCEPT,
    defaultFilter: {
      search: "",
    },
};
