import React, { useState, useRef, useEffect, useMemo, Fragment, useCallback } from 'react';
import * as api from '../../reducer/contains/api'
import {
    axiosWithHeaders
    // , axiosWithHeadersBlob 
} from '../../reducer/contains/axiosWrapper'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Row, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
//import { Dialog } from 'primereact/dialog';
import Lightbox from '../../components/Lightbox-component/index';
import * as Status from '../../common/enum'
import * as actions from '../../reducer/actions/index';
import * as router from '../../common/router';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import Zoom from '../../components/react-img-zoom/index';
import Swal from 'sweetalert2';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useSelector } from 'react-redux';
//import Lightbox from "react-awesome-lightbox";
import moment from 'moment';
var _ = require('lodash');


const DetailSocial = (props: any) => {
    const account: any = useSelector((x: any) => x.auth.profile);
    const [id, setId] = useState('');
    const [detailbill, setDetailBill] = useState<any>();
    const [linkImage, setlinkImage] = useState('');
    const [Bill, setBill] = useState('');
    const [social, setsocial] = useState('ZALO');
    const [socialMarket, setSocialMarket] = useState('ZALO');
    // const [nameEventMarket, setNameEventMarket] = useState('');
    const [socialId, setsocialId] = useState('');
    const [nameEvent, setNameEvent] = useState('#TUONGAN');
    const [supermarketId, setsupermarketId] = useState(0);
    const [supermarketLocation, setSupermarketLocation] = useState<any>(null);
    const [totalBill, setTotalBill] = useState('Tổng giá trị của bill');

    const [listProductMarket, setListProductMarket] = useState([]);

    //Add image platform
    const divLeft = useRef<any>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setvisible] = useState(false);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [heightDevice, setHeightDevice] = useState<number>(0);
    const [naturalWidth, setNaturalWidth] = useState<number>(0);
    const [naturalHeight, setNaturalHeight] = useState<number>(0);
    const [rotate, setRotate] = useState<number>(0);

    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isExist, setIsExist] = useState<boolean>(false);

    useEffect(() => {

        const LoadDetail = (id: any) => {
            axiosWithHeaders('GET', `${api.SOCIAL_DETAIL_BILL}${id}`, null)
                .then((data: any) => {
                    if (data !== undefined && data !== null) {
                        let response = data.data;
                        let { status, message, result, products } = response;
                         console.log(response)
                        if (status === Status.STATUS_RESPONSE_OK) {
                            setListProductMarket(products);
                            const { id, linkImage, socialId, social, msgId, supermarketId, supermarketLocation , bill,supermarketName } = result;
                            setDetailBill(result)
                           
                            let total = 0;
                            products.forEach((item: any) => {
                                if (item.quantity > 0) {
                                    total += (item.total);
                                }
                    
                            });
                            setTotalBill(total.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }))
                        
                
                            setId(id);
                            setBill(bill)
                            setsocial(social);
                            setsocialId(socialId);
                            setlinkImage(linkImage);
                            calculatorSize(linkImage);
                            
                            setsupermarketId(supermarketId)
                            setSupermarketLocation(supermarketLocation)
                            // setNameEvent(nameEvent);
                        }
                        else {
                            props.error_success('error', 'Lỗi', message);
                           // props.history.push(router.Home);
                        }
                    }
                    else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

                }).catch(err => {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
                });

        }


        if (props.match.params.id !== undefined && props.match.params.id !== null) {
            LoadDetail(props.match.params.id);
        }
        else {
          //  props.history.push(router.Home);
        }
    }, [props]);

    // useEffect(() => {
    //     if (divLeft.current.clientHeight > 0) {
    //         setRightHeight(divLeft.current.clientHeight + 280)
    //     }
    // }, [divLeft]);

    const calculatorSize = (src) => {
        const img = new Image();
        img.onload = function (e: any) {
            // alert(e.target.width + 'x' + e.target.height);
            setNaturalWidth(e.target.width);
            setNaturalHeight(e.target.height);
        }
        img.src = src;
    }

    useEffect(() => {
        // console.log(window.innerHeight)
        setHeightDevice(window.innerHeight);
    }, [])



    const renderFooter = (
        <>
            <Row style={{ paddingTop: 10, paddingBottom: 16 }}>
            
            </Row>
        </>
    )
    const renderProduct = useMemo(() => {
  

        const celltemplate = (row: any, column: any) => {
            const { field } = column;
            switch (field) {
                case 'nameBill':
                   // console.log(row)
                    switch (row["isRequired"]) {
                        case true:
                            return (
                                <span style={{ marginLeft: 5, color: "red" ,fontSize:'12px' }}>{row[field]}<br/>
                                    {row.productCode}
                                </span>
                            );
                        default:
                            return (
                                <span style={{ marginLeft: 5 ,fontSize:'12px'  }}>{row[field]}<br/>
                                {row.productCode}</span>
                            );
                    }

                case 'quantity':
                    return (
                        <InputNumber value={row[field]} inputStyle={{ width: '100%' ,fontSize:'12px'  }}  min={0}  disabled={true} />
                    );
                case 'price':
                    return (
                        <InputNumber value={row[field]} inputStyle={{ width: '100%' ,fontSize:'12px'  }}  min={0} disabled={loading} />
                    );
                case 'total':
                    return (
                        <InputNumber value={row[field]} inputStyle={{ width: '100%' ,fontSize:'12px'  }} disabled />
                    );
                default:
                    return null;
            }
        }



        if (listProductMarket !== undefined && listProductMarket.length >= 0) {


            return (
                <DataTable
                    value={listProductMarket}
                    scrollable
                    scrollHeight="280px"
                    emptyMessage="Vui lòng chọn siêu thị..."
                    className="list-product-market"
                    style={{ width: '100%' }}
                    resizableColumns columnResizeMode="fit"
                    showGridlines responsiveLayout="scroll"
                    filters={filters} filterDisplay="menu"
                    loading={loading}
                    lazy
                >
                    <Column field="nameBill" header="Tên sản phẩm" style={{ width: "80%", flexBasis: "80%", flexGrow: 1 , padding:'5px' }} body={celltemplate}></Column>
                    <Column field="quantity" header="Số lượng" style={{ width: "20%", flexBasis: "20%", flexGrow: 1, justifyContent: 'center',padding:'5px' }} body={celltemplate}></Column>
                    {/* <Column field="price" header="Đơn giá" style={{ width: "20%", flexBasis: "20%", flexGrow: 1, justifyContent: 'center',padding:'5px' }} body={celltemplate}></Column>
                    <Column field="total" header="Tổng tiền" style={{ width: "20%", flexBasis: "20%", flexGrow: 1, justifyContent: 'center',padding:'5px' }} body={celltemplate}></Column> */}

                </DataTable>
            )
        }

        return null;
    }, [listProductMarket]);



    const HeaderInfo = (
        <Fragment>
            <Row>
                <Col md={8} className="align-self-center">
                    <strong style={{ fontSize: 14, padding: '10px' }}>THÔNG TIN HÓA ĐƠN</strong>
                </Col>
               
            </Row>
        </Fragment>
    )

    const handleRotate = () => {
        let i = rotate;
        i += 90;
        if (i === 360) {
            i = 0;
        }
        (document.querySelector(".rotate-image-cus") as any).style.transform = `rotate(${i}deg)`;
        (document.querySelector(".rotate-image-cus") as any).style.transformOrigin = `center center`;
        setRotate(i);
    }

    const handleRotateFlip = () => {
        let i = rotate;
        i -= 90;
        if (i === 360) {
            i = 0;
        }
        (document.querySelector(".rotate-image-cus") as any).style.transform = `rotate(${i}deg)`;
        (document.querySelector(".rotate-image-cus") as any).style.transformOrigin = `center center`;
        setRotate(i);
    }

    const renderImage = (widthImage, heightImage, max_width, max_height) => {
        if (linkImage !== "") {
            const image = [{
                src: linkImage,
                alt: linkImage,
                title: linkImage,
                description: linkImage
            }]

            // console.log(widthImage, heightImage, max_width, max_height);

            return (
                <Lightbox
                    images={image}
                    renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                        return (
                            <div key={idx} className="d-flex justify-content-center align-items-center" style={{ position: "relative", padding: 5, width: `100%` }}>
                                <div className='rotate-image-cus'>
                                    <Zoom
                                        img={image.src}
                                        // img="http://api-testtuongan.tcgh.com.vn/wwwroot/30062022/azVsu1JUHWbggP78ChzQOJyVfcflXKEGSrP5Y3RGB0jqzDPf7Rz83EGtDpCxyVUq2rJRYJb2HLClIX4z.jpg"
                                        zoomScale={3}
                                        width={widthImage}
                                        height={heightImage}
                                        backgroundSize="contain"
                                    />
                                </div>
                                <div style={{ position: "absolute", top: 2, right: -12, opacity: "0.7" }}>
                                    <Button icon="pi pi-search-plus" className="p-button-raised btn-zoom p-button-sm" onClick={toggleLightbox.bind(null, idx)} style={{ marginRight: 10 }} />
                                    <Button icon="pi pi-refresh" className="p-button-raised btn-zoom p-button-sm" onClick={handleRotate} style={{ marginRight: 10 }} />
                                    <Button icon="pi pi-replay" className="p-button-raised btn-zoom p-button-sm" onClick={handleRotateFlip} />
                                </div>
                            </div>
                        )
                    }} />
            )

        }
        return null;
    }

    const renderDivImage = () => {
        let width = 0;
        let height = 0;
        let max_height = 0;
        let max_width = 0;

        let element = document.querySelector(".div-image-zalo")?.getBoundingClientRect();
        if (element) {
            max_width = element.width - 30;
            max_height = element.height;
        }

        if (naturalWidth > 0 && naturalHeight > 0 && max_width > 0 && max_height > 0) {




            if (naturalWidth < naturalHeight) {
                height = max_height;
                width = (naturalWidth * height) / naturalHeight;
                return (
                    <div className='d-flex align-items-center' style={{ height: max_height, width: max_width, position: `relative` }}>
                        {renderImage(width, height, max_width, max_height)}
                    </div>

                )
            }
            else {
                width = max_width * 0.8;
                height = (naturalHeight * width) / naturalWidth;
                return (
                    <div className='d-flex align-items-center' style={{ height: max_height, width: max_width, position: `relative` }}>
                        {renderImage(width, height, max_width, max_height)}
                    </div>

                )
            }
        }

        return null;
    }

    return (
        <Fragment>
            <Row style={{ paddingTop: 15 }}>
                <Col md={5} ref={divLeft} style={{ maxHeight: heightDevice - 80, overflowY: `auto` }}>
                    <Row>
                        <Col md={12} className="align-self-center">
                            <span
                                style={{ fontSize: 20 }}
                            ><Button
                                    icon="pi pi-angle-left"
                                    onClick={() => {
                                        props.history.push(router.ListBillAccept);
                                    }}
                                    className="p-button-raised p-button-sm"
                                    style={{ marginRight: 5 }}
                                />      NHẬP THÔNG TIN</span>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                        <Col md={12}>
                            <Card title={<strong style={{ fontSize: 14 }}>SIÊU THỊ</strong>} className="card-social">
                                {/* <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Platform:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{renderImagePlatform} {social}</span>
                                    </Col>
                                </Row> */}
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Thời gian gửi:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span className='text-danger' style={{ fontWeight: 400 }}>{detailbill?.stringDate + ` `+detailbill?.stringTime}</span>
                                    </Col>
                                </Row>
                                {account.roleId !== 4 ?<Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Thời gian duyệt: </span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span className='text-danger' style={{ fontWeight: 400 }}>{detailbill?.stringDateAccept + ` `+detailbill?.stringTimeAccept}</span>
                                    </Col>
                                </Row> : null}
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Tên khách hàng:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.name}</span>
                                    </Col>
                                </Row>
                               
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Số điện thoại:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.phone}</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Địa chỉ:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.address}</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>CCCD:</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.code}</span>
                                    </Col>
                                </Row>
                                {/* <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Hệ thống siêu thị</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.supermarketName}</span>
                                    </Col>
                                </Row> */}
                                <Row style={{ paddingTop: 10, paddingBottom: 16 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Hệ thống siêu thị</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.supermarketLocation}</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Ghi chú</span>
                                    </Col>
                                    <Col md={8} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>{detailbill?.note}</span>
                                    </Col>
                                </Row>
                                {/* <Row style={{ paddingTop: 10, paddingBottom: 16 }}>
                                    <Col md={12} className="text-center">
                                        <Button className="p-button-raised p-button-success p-button-sm" label="DUYỆT NHANH" icon="pi pi-check" onClick={() => AcceptBill()} disabled={(supermarketId === 0) ? true : false} style={{ marginRight: 15 }} />
                                    </Col>
                                </Row> */}
                            </Card>


                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                        <Col md={12}>
                            <Card header={HeaderInfo}>
                                <Row>
                                    <Col md={4} className="align-self-center">
                                        <span style={{ fontSize: 14 }}>Mã bill: </span>
                                    </Col>
                                    <Col md={8}>
                                        <InputText
                                            name="Bill"
                                            value={Bill}
                                            placeholder="Mã Bill"
                                            style={{ width: "100%", fontSize: 14 }}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>

                                    {renderProduct}
                                </Row>
                                {renderFooter}
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={7} className="div-image-zalo">
                    {renderDivImage()}
                    {/* <Lightbox image={linkImage} title="Image Title"></Lightbox> */}
                </Col>
            </Row>
          
        </Fragment>

    )

}


const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail)),
    }
}
export default connect(null, mapDispatchToProps)(DetailSocial);