import * as types from '../contains/actionTypes';
import { enCryptData, enCryptDataClient, GetProfileString } from '../../authentication/crypto';
import { LOGIN } from '../contains/api';
// import {header} from '../contains/header';
import * as Status from '../../common/enum';
// import * as router from '../../common/router'
import { REACT_APP_JWT, REACT_APP_JWT_REFESH, REACT_APP_JWT_PROFILE } from '../../common/config';
import { axiosWithHeadersNoToken } from '../contains/axiosWrapper';
import Swal from 'sweetalert2';
import { saveBreadcrumb } from './breadcrumb';
export const auth_ini = () => {
    return {
        type: types.AUTH_INI
    };
};

export const auth_success = (token, profile, refresh) => {
    return {
        type: types.AUTH_SUCCESS,
        token,
        profile,
        refresh
    }
}


export const auth_logout = () => {
    return {
        type: types.AUTH_LOGOUT
    }
}

export const auth_reload_token = (token) => {
    localStorage.setItem(REACT_APP_JWT as string, enCryptDataClient(token));
    return {
        type: types.AUTH_RELOAD_TOKEN,
        token
    }
}



export const auth_loading = (loading) => {
    return {
        type: types.LOADING,
        loading
    }
}



export const auth_fail = (severity, summary, detail) => {
    return {
        type: types.ERROR_SUCCESS,
        severity
        , summary
        , detail
    }
}

export const error_init = () => {
    return {
        type: types.ERROR_INI
    }
}


export function login(username: string, password: string) {
    return async dispatch => {
        dispatch(auth_loading(true));
        var obj = { "Username": username, "Password": password }
        //var deCode = enCryptData(obj);
        // console.log(obj)
        // if (!deCode) {
        //     dispatch(auth_fail('error','ERROR',"Không thể đăng nhập"));
        //     return null;
        // }
        axiosWithHeadersNoToken('POST', LOGIN, obj)
            .then((data: any) => {
                // console.log(data);
                if (data.status !== Status.STATUS_RESPONSE_OK || data.data.status !== Status.STATUS_RESPONSE_OK) {
                    //  console.log(data.data.message);
                    dispatch(auth_ini());
                    // dispatch(auth_fail('error', 'ERROR', data.data.message));
                    Swal.fire("Lỗi", data.data.message, "error")
                    //alert(data.data.message);
                } else {
                    // let browserToken = localStorage.getItem(REACT_APP_JWT as string);
                    // if (browserToken) {
                    dispatch(error_init());
                    const { id, roleId, name, accesstoken, refreshtoken } = data.data
                    localStorage.setItem(REACT_APP_JWT as string, enCryptDataClient(accesstoken));
                    localStorage.setItem(REACT_APP_JWT_REFESH as string, enCryptDataClient(refreshtoken));
                    localStorage.setItem(REACT_APP_JWT_PROFILE as string, enCryptDataClient({ id, roleId, name }));
                    // }
                    // console.log(accesstoken);
                    dispatch(auth_success(accesstoken, GetProfileString({ id, roleId, name }), refreshtoken));

                    // saveBreadcrumb("Trang chủ", "/", "ik ik-home", "", 1, 0, "");
                    // dispatch(breadcrumb_update("Trang chủ", router.Home, "ik ik-home", "", 0));
                    // saveLSBreadcrumb("Trang chủ", router.Home, "ik ik-home", "", 0)
                }
            }).catch(err => {
                console.log(err);
                dispatch(auth_fail('error', 'ERROR', "Lỗi hệ thống!"));
                dispatch(auth_loading(false));
            });
    }
}

export function logOut() {
    return async dispatch => {
        localStorage.removeItem(REACT_APP_JWT as string);
        localStorage.removeItem(types.LOCAL_STORAGE_BREADCRUMB);
        dispatch(auth_fail(null, null, null));
        dispatch(auth_logout());
    }
}