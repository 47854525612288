// LOADING
export const LOADING = 'LOADING';

export const AUTH_INI = 'AUTH_INI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_RELOAD_TOKEN = 'AUTH_RELOAD_TOKEN';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//ERROR
export const ERROR_INI = 'ERROR_INI';
export const ERROR_SUCCESS = 'ERROR_SUCCESS';


//WAREHOUSE
export const WAREHOUSE_INI = 'WAREHOUSE_INI';
export const WAREHOUSE_SUCCESS = 'WAREHOUSE_SUCCESS';
export const WAREHOUSE_FAIL = 'WAREHOUSE_FAIL';

//ROUTER
export const ROUTER_INI = 'ROUTER_INI';
export const ROUTER_DATA_SUCCESS = 'ROUTER_DATA_SUCCESS';
export const ROUTER_DROPDOWN_SUCCESS = 'ROUTER_DROPDOWN_SUCCESS';
export const ROUTER_FAIL = 'ROUTER_FAIL';

//DATA DEFAULT
export const DATA_DEFAULT_INI = 'DATA_DEFAULT_INI';
export const DATA_DEFAULT_SUCCESS = 'DATA_DEFAULT_SUCCESS';
export const DATA_DEFAULT_FAIL = 'DATA_DEFAULT_FAIL';

// BOOKING
export const INIT_BOOKING_DETAIL = 'INIT_BOOKING_DETAIL';
export const BOOKING_DETAIL = 'BOOKING_DETAIL';
// export const SET_BOOKING_DETAIL = 'SET_BOOKING_DETAIL';
// export const SET_BOOKING_LIST_ROUTER = 'SET_BOOKING_LIST_ROUTER';

//FILTER
export const SET_FILTER_PARAM = 'SET_FILTER_PARAM';

//RECEIPT DELIVERY
export const SET_RECEIPT_DELIVERY_DETAIL = 'SET_RECEIPT_DELIVERY_DETAIL';
export const SET_RECEIPT_DELIVERY_LIST_DETAIL = 'SET_RECEIPT_DELIVERY_LIST_DETAIL';

//BREADCRUMB
export const BREADCRUMB_INI = 'BREADCRUMB_INI';
export const BREADCRUMB_CHANGE = 'BREADCRUMB_CHANGE';
export const BREADCRUMB_CHANGE_DESC = 'BREADCRUMB_CHANGE_DESC';
export const LOCAL_STORAGE_BREADCRUMB = 'PLNYTYOHJT';
export const LOCAL_STORAGE_TABLE = 'AAN521YOHJT';