import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    isLoading: false,
    objFilter: null,
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.SET_FILTER_PARAM:
            return {
                ...state,
                objFilter: action.objFilter,
            };
        default:
            return state;
    }
}

export default reducer;