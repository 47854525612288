// import '../assets/css/font-awesome/css/font-awesome.css';
// import '../assets/css/icon-kit/dist/css/iconkit.min.css';
// import '../assets/css/ionicons/dist/css/ionicons.min.css';
// import '../assets/css/style.css';
// import '../assets/css/custom/customize.css';
// import '../assets/css/custom/responsive.scss';
import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
// import Header from './header/header';
// import SideBar from './sidebar/index';
// import PageHeader from './pageHeader';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../global';
import { theme } from '../theme';
import { Burger, MenuMobile, Menu } from '../components/menu';
import { useOnClickOutside } from '../hooks';
import styled from 'styled-components';
import Error from './showError';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import * as actions from '../reducer/actions';
import * as crypto from '../authentication/crypto';

// const HeaderPage = styled.header`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 45px;
//     box-shadow: 1px 5px 25px rgb(0 0 0 / 10%);

//     @media (max-width: ${({ theme }) => theme.mobile}) {
//         width: 100%;
//     }
// `

const MainPage = styled.main`
    width: 100%;
    height: 100%;
    padding: 15px 0px;

    .container-fluid{
        height: 100%;
    }

    .page-content{
        width: 100%;
        height: 100%;
        position: relative;
        padding-left: 20px;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
        .page-content{
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 0;
        }
    }

    .page-header{
        height: 60px;
    }

    .dropdown-toggle{
        display: flex;
        align-items: center;
    }

    .btn-admin{
        background-color: transparent;
        border: transparent;
        color: #000;        
    }

    .btn-admin:focus{
        background-color: transparent;
        border: transparent;
        color: #000;      
        box-shadow:unset;
        outline: 0  
    }

    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-admin.dropdown-toggle{
        background-color: transparent;
        border: transparent;
        color: #000;      
        box-shadow:unset;
        outline: 0  
    }

    a{
        margin-top: 0;
    }

    .page-content .card{
        padding: 10px;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
    }
`

const Layout = (props: any) => {
    // const wrapper = React.useRef<any>(null);
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(true);
    const [account, setAccount] = useState<any>();
    const node: any = useRef();

    const onLogout = async () => {
        return dispatch(actions.logOut())
    }

    useEffect(() => {
        // console.log(crypto.GetProfile())
        setAccount(crypto.GetProfile())
    }, [])

    useOnClickOutside(node, () => setOpen(false));    

    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <GlobalStyles />
                {/* <div className="wrapper nav-collapsed menu-collapsed"
                    style={{ top: 0, position: "absolute" }}
                    ref={wrapper} >
                    <Header wrapper={wrapper} />

                    <div className="page-wrap">
                        <SideBar
                            wrapper={wrapper}
                        />
                        <div className="main-content">
                            <div style={{ position: 'relative' }}>
                                {
                                    props.children
                                }
                            </div>

                        </div>
                    </div>
                    
                </div> */}
                <MainPage>
                    <div ref={node}>
                        <Burger open={open} setOpen={setOpen} />
                        <MenuMobile open={open} />
                    </div>
                    <div className="container-fluid">
                        <div className='d-flex' style={{ height: `100%` }}>
                            <Menu open={openMenu} setOpen={setOpenMenu} />
                            <div className='page-content'>
                                <div className="container-fluid">
                                    <div className='page-header'>
                                        <div className="d-flex align-items-center justify-content-end">
                                            {account && (
                                                <Dropdown align={"end"}>
                                                    <Dropdown.Toggle id="dropdown-basic" className='btn-admin'>
                                                        <div style={{ padding: `0 10px` }}>
                                                            <b style={{textTransform: "capitalize"}}>{account && account.name}</b>
                                                        </div>
                                                        <Avatar label={(account && account.name) ? (account.name.substr(0,1)): "D"} shape="circle" />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={onLogout}>Đăng xuất</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </MainPage>
                <Error />
            </Fragment>
        </ThemeProvider>
    )
}

export default Layout;