import React, { Fragment, useEffect, useState } from 'react';
import DataView from '../../components/dataview/index2';

import {
    configGrid,
} from '../../components/dataview/data/social';

import Lightbox from '../../components/Lightbox-component/index';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import * as router from '../../common/router';
import moment from 'moment';

const ItemImage = styled.div`
    width: 225px;
    height: 225px;
`
const ItemImageContent = styled.div`
    width: calc(100% - 225px);
    padding-left: 10px;
`

const Index = () => {   
    const [countdown, setCountdown] = useState(0);    
    const [timeMod, setTimeMod] = useState(0);

    useEffect(() => {
        setTimeMod(moment().valueOf());
    }, [])
    

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCountdown(countdown + 1);
            setTimeMod(moment().valueOf());
        }, 60000);
        return () => {
            clearTimeout(timeout);
        }
    }, [countdown]);

    const renderListItem = (item: any) => {

        const image = [{
            src: item.linkImage,
            alt: item.supermarketName,
            title: item.supermarketName,
            description: ""
        }]

        return (
            <div className='col-12'>
                <div className="d-flex" style={{ padding: `10px 15px`, position: 'relative', overflowX: `hidden` }}>
                    <ItemImage>
                        <Lightbox
                            images={image}
                            renderImageFunc={(idx: number, image: any, toggleLightbox: any) => {
                                return (
                                    <img
                                        key={idx}
                                        src={image.src}
                                        alt="Hình mô tả"
                                        style={{ width: 225, height: 225, objectFit: "contain", cursor: "pointer", backgroundColor: `#f4f4f4`, borderRadius: 10 }}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                )
                            }} />
                    </ItemImage>
                    <ItemImageContent>
                        <div className='container-fluid'>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <b>{`#` + item.id}</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className="row">
                                        <div className='col-sm-12' style={{ marginTop: 5 }}>
                                            <span style={{ fontSize: 12 }}>Trạng thái</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-12' style={{ marginTop: 10 }}>
                                            <span style={{ fontWeight: 400 }}>{item.nameStatus}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className="row">
                                        <div className='col-sm-12' style={{ marginTop: 5 }}>
                                            <span style={{ fontSize: 12 }}>Thời gian gửi</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-12' style={{ marginTop: 10 }}>
                                            <span className='text-danger' style={{ fontWeight: 400 }}>{item.stringDate + ` ` + item.stringTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 5 }}>
                                    <span style={{ fontSize: 12 }}>Tài khoản duyệt</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <b>{item.nameUserAssign}</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 5 }}>
                                    <span style={{ fontSize: 12 }}>Người gửi</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <b>{item.socialId}</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 5 }}>
                                    <span style={{ fontSize: 12 }}>Hệ thống siêu thị</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <b>{item.supermarketName}</b>
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <Link
                                        className="p-link"
                                        to={`${router.Social}/${item.id}`}
                                        >
                                        <Button icon="pi" label="DUYỆT HÓA ĐƠN" className='p-button-sm p-button-rounded' />
                                    </Link>
                                    <Link
                                        style={{paddingLeft:'15px'}}
                                        className="p-link"
                                        to={`${router.Social}/${item.id}`}
                                        target="_blank">
                                        <Button icon="pi pi-external-link" label="DUYỆT NEW TAB" className='p-button-sm' />
                                    </Link>
                                    {/* <Link
                                        className="p-link"
                                        to={`${router.SocialClone}/${item.id}`}
                                        >
                                        <Button icon="pi" label="DUYỆT" className='p-button-sm p-button-rounded' />
                                    </Link> */}
                                </div>
                            </div>
                        </div>

                        {/* {JSON.stringify(item)} */}
                    </ItemImageContent>
                </div>
            </div>
        );
    }

    const itemTemplate = (item: any, layout: string) => {
        if (item) {
            if (layout === "list") {
                return renderListItem(item);
            }
        }
        return;
    }


    return (
        <Fragment>
            <DataView
                header='Danh sách hóa đơn'
                filters={[]}
                config={configGrid}
                itemTemplate={itemTemplate}
                timeUpdate={timeMod}
                // value={data}
            />
        </Fragment>
    );
}

export default Index;