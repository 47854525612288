import React, { Fragment, useState,useEffect } from "react";
import { Button } from 'primereact/button';
import Swal from "sweetalert2";

import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';

import * as actions from '../../reducer/actions/index';
import * as api from '../../reducer/contains/api';
import { connect } from 'react-redux';
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import styled from 'styled-components';
import * as DropdownList from '../../components/filter/list-dropdown';
import loadingAnimation from './loading.json';
import Lottie from 'react-lottie';
import * as Status from '../../common/enum'
import TreemapChart from '../../components/chart/apexchart/treemapchart'
import LineChart from '../../components/chart/apexchart/linechart'
import RadiaBarChart from '../../components/chart/apexchart/radialbarschart'
import AreaChart from '../../components/chart/apexchart/areachart'
import BarChart from '../../components/chart/apexchart/barchart'
import {
  listColumn
} from '../../components/datatable/data/datatableProduct';

import { Card } from 'primereact/card';
 
const FilterComponent = styled.section`
    margin-bottom: 10px;

    .p-dropdown .p-dropdown-trigger{
        width: 1.75rem;
    }
`;

const Dashboard = (props) => {
    const [loading, setLoading] = useState(true);
    const [supermarketId, setSupermarketId] = useState("0");
    var date = new Date();    
    if(date > new Date("2023-11-06")){
      date.setDate(date.getDate() -7);
    }
    else{
      date = new Date("2023-11-01")
    }


    const [dateRange, setDateRange] = useState<any>([date, new Date()]);
    const [error, setError] = useState('');
    const [optionsLine, setOptionLine] = useState<any>(null);
    const [optionsBar, setOptionBar] = useState<any>(null);
    const [optionsRadiaBar, setOptionsRadiaBar] = useState<any>(null);
    const [series, setSerios] = useState<any>(null);
    const [seriesBarchart, setSeriesBarchart] = useState<any>(null);
    const [total, setTotal] = useState<any>(null);
    const [masterdata, setMasterData] = useState<any>(null);
    const [linePrd, setLinePrd] = useState<any>([]);
    const [listProductSupermarket, setListProductSupermarket] = useState<any>([]);
    const [listBillSuccess, setListBillSuccess] = useState<any>([]);
    const [tableProduct, setTableProduct] = useState<any>([]);
    const [seriesRadiaBar, setSeriosRadiaBar] = useState<any>(null);
    const [widthDevice, setWidthDevice] = useState<number>(0);
    useEffect(() => {
        LoadDataDashboard()
        LoadDataDashboardDate()
    }, []);
    useEffect(() => {
      // console.log(window.innerHeight)
      setWidthDevice(window.innerWidth);
  }, [])
    const SearchData=()=>{
      setLoading(true);
      LoadDataDashboard()
      LoadDataDashboardDate()
    }
    const LoadDataDashboard=()=>{
        axiosWithHeaders('POST', `${api.DASHBOARD_ALL}`, { dateFrom:"", dateTo:"" })
        .then((data: any) => {

            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message ,listBillSuccess,listProductSupermarket,masterdata } = response;
              //  console.log(listProductSupermarket)
                if (status === Status.STATUS_RESPONSE_OK) {
                  setMasterData(masterdata)
                  setListProductSupermarket(listProductSupermarket)
                  setListBillSuccess([{data:listBillSuccess}])
                  setLoading(false) 
                }
                else {
                    props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });

    }

    const LoadDataDashboardDate=()=>{
      let dateFrom: any = dateRange[0];
      let dateTo: any = dateRange[1];
      dateFrom = moment(dateFrom).format('YYYYMMDD');
      dateTo = moment(dateTo).format('YYYYMMDD');

      axiosWithHeaders('POST', `${api.DASHBOARD_BARCHART}`, { dateFrom, dateTo })
      .then((res: any) => {

          if (res !== undefined && res !== null) {
              let response = res.data;
              let { status, message ,data } = response;
            //  console.log(listProductSupermarket)
             // console.log(response)
              if (status === Status.STATUS_RESPONSE_OK) {
             
                  const {xcategories , colors,series,maxData} = data
                //  setSerios(series)
                console.log(xcategories , colors,series,maxData)
                  setSeriesBarchart(series)
                  let options = {
                    chart: {
                      type: 'bar',
                      height: 350
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                      },
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      colors: ['transparent']
                    },
                    xaxis: {
                      categories: xcategories
                    },
                    yaxis: {
                        title: {
                          text: 'Số lượng',
                          style:{
                              fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                            }
                        },
                        min: 0,
                        max: maxData+20
                    },
                    fill: {
                      opacity: 1
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return  val 
                        }
                      }
                    }
                  }
                
                  // let  options = {
                  //     chart: {
                  //       height: 400,
                  //       type: 'line',
                  //       dropShadow: {
                  //         enabled: true,
                  //         color: '#000',
                  //         top: 18,
                  //         left: 7,
                  //         blur: 10,
                  //         opacity: 0.2
                  //       },
                  //       toolbar: {
                  //         show: false
                  //       }
                  //     },
                  //     colors: colors,
                  //     dataLabels: {
                  //       enabled: false,
                  //     },
                  //     stroke: {
                  //     width: [1,1,1,1,1,1,1,1,1,1,1,1],
                  //       curve: 'smooth'
                  //     },
                  //     title: {
                  //       text: 'BIỂU ĐỒ THỐNG KÊ MÃ DỰ THƯỞNG THAM GIA THEO THỜI GIAN',
                  //       align: 'center',
                  //       style:{
                  //         fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                  //       }
                  //     },
                  //     // grid: {
                  //     //   borderColor: '#e7e7e7',
                  //     //   row: {
                  //     //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  //     //     opacity: 0.5
                  //     //   },
                  //     // },
                  //     markers: {
                  //       size: 3,
                  //       //colors:colors
                  //     },
                  //     xaxis: {
                  //       categories: xcategories
                  //     },
                  //     yaxis: {
                  //       title: {
                  //         text: 'Số lượng',
                  //         style:{
                  //             fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                  //           }
                  //       },
                  //       min: 0,
                  //       max: maxData+20
                  //     },
                  //     // legend: {
                  //     //   position: 'top',
                  //     //   horizontalAlign: 'right',
                  //     //   //floating: true,
                  //     //   offsetY: -25,
                  //     //   offsetX: -5
                  //     // }
                  //   }
                    setOptionBar(options)              
                    setLoading(false)
                
              }
              else {
                  props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
              }
          }
          else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

      }).catch(err => {
          props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
      });

      // axiosWithHeaders('POST', `${api.DASHBOARD}`, { dateFrom, dateTo })
      // .then((data: any) => {

      //     if (data !== undefined && data !== null) {
      //         let response = data.data;
      //         let { status, message ,line,lineProduct } = response;
      //       //  console.log(listProductSupermarket)
      //         if (status === Status.STATUS_RESPONSE_OK) {
             
      //           setLinePrd(lineProduct)
     
               
      //             const {xcategories , colors,series,maxData} = line
      //           //  console.log(colors)
      //             setSerios(series)
      //             let  options = {
      //                 chart: {
      //                   height: 400,
      //                   type: 'line',
      //                   dropShadow: {
      //                     enabled: true,
      //                     color: '#000',
      //                     top: 18,
      //                     left: 7,
      //                     blur: 10,
      //                     opacity: 0.2
      //                   },
      //                   toolbar: {
      //                     show: false
      //                   }
      //                 },
      //                 colors: colors,
      //                 dataLabels: {
      //                   enabled: false,
      //                 },
      //                 stroke: {
      //                 width: [1,1,1,1,1,1,1,1,1,1,1,1],
      //                   curve: 'smooth'
      //                 },
      //                 title: {
      //                   text: 'BIỂU ĐỒ THỐNG KÊ MÃ DỰ THƯỞNG THAM GIA THEO THỜI GIAN',
      //                   align: 'center',
      //                   style:{
      //                     fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
      //                   }
      //                 },
      //                 // grid: {
      //                 //   borderColor: '#e7e7e7',
      //                 //   row: {
      //                 //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //                 //     opacity: 0.5
      //                 //   },
      //                 // },
      //                 markers: {
      //                   size: 3,
      //                   //colors:colors
      //                 },
      //                 xaxis: {
      //                   categories: xcategories
      //                 },
      //                 yaxis: {
      //                   title: {
      //                     text: 'Số lượng',
      //                     style:{
      //                         fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
      //                       }
      //                   },
      //                   min: 0,
      //                   max: maxData+20
      //                 },
      //                 // legend: {
      //                 //   position: 'top',
      //                 //   horizontalAlign: 'right',
      //                 //   //floating: true,
      //                 //   offsetY: -25,
      //                 //   offsetX: -5
      //                 // }
      //               }
      //               setOptionLine(options)              
      //               setLoading(false)
                
      //         }
      //         else {
      //             props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + message);
      //         }
      //     }
      //     else { props.error_success('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

      // }).catch(err => {
      //     props.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err);
      // });

  }
    const renderRadialBarCharSupermarket=()=>{
      if(listProductSupermarket !== undefined && listProductSupermarket != null && listProductSupermarket.length > 0)
      {
        var arr = [] as any;
        let height = ((widthDevice -350) /4 )
        let fontsize = height  > 300 ? '9px': '6px'
        let fontsizeTotal = height  > 300 ? '10px': '8px'
        let classdiv = height  > 300 ? "col-sm-12 col-md-6": "col-sm-12 col-md-12"
       // console.log(height)
        listProductSupermarket.map((item,idx)=>{
          const {labels, series,colors,supermarketName,products,total} = item
          
          arr.push( <div key={idx} className={classdiv} style={{margin:'auto'}} >
                  <RadiaBarChart options={{
                        chart: {
                          height: height,
                          type: 'radialBar',
                        },
                        plotOptions: {
                          radialBar: {
                            offsetY: 0,
                            startAngle: 0,
                            endAngle: 270,
                            hollow: {
                              margin: 5,
                              size: '30%',
                              background: 'transparent',
                              image: undefined,
                            },
                            dataLabels: {
                              name: {
                                show: true,
                                fontSize: fontsize,
                              },
                              value: {
                                show: true,
                              },
                              total: {
                                show: true,
                                label: supermarketName,
                                fontSize: fontsizeTotal,
                                formatter: function (w) {
                                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                  return total
                                }
                                
                              }
                            }
                          }
                        },
                        colors: colors,
                        labels: labels,
                        legend: {
                          show: true,
                          floating: true,
                          fontSize: fontsize,
                          position: 'left',
                          labels: {
                            useSeriesColors: true,
                          },
                          markers: {
                            size: 0
                          },
                          formatter: function(seriesName, opts) {
                            //console.log(opts.w.globals)
                            return seriesName + ":  " + products[opts.seriesIndex].now + "/" + products[opts.seriesIndex].total 
                          },
                          itemMargin: {
                            vertical: 3
                          }
                        },
                        title: {
                          text: "BIỂU ĐỒ SỐ LƯỢNG QUÀ ĐÃ TRÚNG / TỔNG SỐ LƯỢNG QUÀ",
                          align: 'center',
                          style:{
                            fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                          }
                        },
                        responsive: [{
                          breakpoint: 300,
                          options: {
                            legend: {
                                show: false
                            }
                          }
                        }]
                      }} series={series} height={height} />
        </div>)
        })
        return arr;
      }
       return null
    }

    const renderDashboardLinePrdSupermarket=()=>{
      if(linePrd !== undefined && linePrd != null && linePrd.length > 0)
      {
          let arr =[] as any;
          linePrd.map((item,idx)=>{
            const {xcategories , colors,series,maxData,name} = item
            arr.push(
              <div key={idx} className=" col-sm-12 col-md-12">
               <LineChart options={{
                        chart: {
                          height: 400,
                          type: 'line',
                          dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                          },
                          toolbar: {
                            show: false
                          }
                        },
                        colors: colors,
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                        width: [1,1,1,1,1,1,1,1,1,1,1,1],
                          curve: 'smooth'
                        },
                        title: {
                          text: "BIỂU ĐỒ SỐ LƯỢNG QUÀ ĐÃ TRÚNG THEO THỜI GIAN",
                          align: 'center',
                          style:{
                            fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                          }
                        },
                        // grid: {
                        //   borderColor: '#e7e7e7',
                        //   row: {
                        //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        //     opacity: 0.5
                        //   },
                        // },
                        markers: {
                          size: 3,
                          //colors:colors
                        },
                        xaxis: {
                          categories: xcategories
                        },
                        yaxis: {
                          title: {
                            text: 'Số lượng',
                            style:{
                                fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                              }
                          },
                          min: 0,
                          max: maxData+20
                        },
                        // legend: {
                        //   position: 'top',
                        //   horizontalAlign: 'right',
                        //   //floating: true,
                        //   offsetY: -25,
                        //   offsetX: -5
                        // }
                      }} series={series} height={400}/>
                                
         </div>

            )
          })
          return arr;
      }
      return null;
    }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
    return (
      <div style={{width:'95%'}}>
        <div className="row">
          <div className="col-md-12">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                width: `100%`,
                borderBottom: `1px solid #f4f4f4`,
                paddingBottom: `10px`,
              }}
            >
              <span style={{ fontSize: 24, fontWeight: 500 }}>Dashboard</span>
            </div>
          </div>
        </div>
       
        {loading ? (
          <div className="row" style={{ paddingTop: 20 }}>
            <div className="col-md-12 d-flex justify-content-center">
              <Lottie
                options={defaultOptions}
                height={400}
                width={400}
                isStopped={false}
                isPaused={false}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-content-center flex-wrap">
              <div className="col-sm-12 col-md-3">
                <Card style={{backgroundColor:'#ffcf40' , marginBottom:'5px'}}
                
                header={<div style={{fontSize:'20px', color:'white',fontWeight:'bold',padding:'10px'}}><i className="pi pi-users" style={{'fontSize': '2em'}}></i> <span>SỐ LƯỢNG NGƯỜI CHƠI</span></div>}
                  className="col-sm-12 col-md-12"
                >
                 <p style={{fontSize:'30px',color:'white',textAlign:'center',padding:0,margin:0,fontWeight:'bolder'}}>{masterdata !== undefined &&
                    masterdata !== null &&
                    masterdata.totalUser}</p>  
                </Card>
                <Card style={{backgroundColor:'#b79e79' , marginTop:'15px'}}
                  header={<div style={{fontSize:'20px', color:'white',fontWeight:'bold',padding:'10px'}}><i className="pi pi-file" style={{'fontSize': '2em'}}></i> <span>MÃ DỰ THƯỞNG</span></div>}
                  className="col-sm-12 col-md-12"
                >
                   <p style={{fontSize:'20px',color:'white',textAlign:'left',padding:0,margin:0}}>
                    TỔNG SỐ MÃ  :{" "}
                    <b>{masterdata !== undefined &&
                      masterdata !== null &&
                      masterdata.totalLucky.toLocaleString('it-IT')}</b>
                  </p>
                  <p style={{fontSize:'20px',color:'white',textAlign:'left',padding:0,margin:0}}>
                    {" "}
                    ĐÃ THAM GIA :{" "}
                    <b>{masterdata !== undefined &&
                      masterdata !== null &&
                      masterdata.total}</b>
                  </p>
                </Card>
              </div>
              <div className=" col-sm-12 col-md-9" >
                  {renderRadialBarCharSupermarket()}
              </div>
            </div>
            {/* <div className=" col-sm-12 col-md-12">
                {listBillSuccess !== undefined && listBillSuccess !== null && (
                  <TreemapChart
                    options={{
                      legend: {
                        show: true,
                      },
                      chart: {
                        heigh: 400,
                        type: "treemap",
                        toolbar: {
                          show: false
                        }
                      },
                      title: {
                        text: "BIỂU ĐỒ SỐ LƯỢNG HÓA ĐƠN HỢP LỆ CỦA SIÊU THỊ",
                        style:{
                          fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto'
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        style: {
                          fontSize: "12px",
                        },
                        formatter: function (text, op) {
                          return [text, op.value];
                        },
                        offsetY: -4,
                      },
                      plotOptions: {
                        treemap: {
                          enableShades: true,
                          shadeIntensity: 0.5,
                          reverseNegativeShade: true,
                          colorScale: {
                            ranges: [
                              {
                                from: -6,
                                to: 0,
                                color: '#CD363A'
                              },
                              {
                                from: 0.001,
                                to: 6,
                                color: '#52B12C'
                              }
                            ]
                          }
                        }
                      }
                    }}
                    series={listBillSuccess}
                    height={400}
                  />
                )}
              </div> */}

          <FilterComponent className="col-md-12">
          <div className="col-md-12">
            <div className="card">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <span style={{ fontSize: 18, fontWeight: 500 }}>
                      Bộ lọc
                    </span>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-md-4 col-lg-2" key={`from_date-1`}>
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: 10 }}>
                        <span>Từ ngày - Đến ngày</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: 10 }}>
                        <Calendar
                          style={{ width: `100%` }}
                          value={dateRange}
                          onChange={(e: any) => setDateRange(e.value)}
                          selectionMode="range"
                          dateFormat="dd/mm/yy"
                          readOnlyInput
                          placeholder="dd/mm/yyyy - dd/mm/yyyy"
                          monthNavigator
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-2" key={`action-button`}>
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: 10 }}>
                        <span>&nbsp;</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: 10 }}>
                        <Button
                          label="Tìm kiếm"
                          className="p-button-warning"
                          loading={loading}
                          onClick={() => SearchData()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {error !== "" && (
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ marginTop: 5, color: `red` }}
                  >
                    {error}
                  </div>
                </div>
              )}
            </div>
          </div>
        </FilterComponent>
            {/* <div className="col-sm-12 col-md-12">
              {series !== undefined &&
                series !== null &&
                optionsLine !== undefined &&
                optionsLine !== null && (
                  <AreaChart
                    options={optionsLine}
                    series={series}
                    height={400}
                  />
                )}
            </div> */}

          <div className="col-sm-12 col-md-12">
              {seriesBarchart !== undefined &&
                seriesBarchart !== null &&
                optionsBar !== undefined &&
                optionsBar !== null && (
                  <BarChart
                    options={optionsBar}
                    series={seriesBarchart}
                    height={400}
                  />
                )}
            </div>

            
            {/* <div
              className="flex justify-content-center flex-wrap col-sm-12 col-md-12"
            >
              {renderDashboardLinePrdSupermarket()}
            </div> */}
          </>
        )}
      </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity, summary, detail) => dispatch(actions.error_success(severity, summary, detail)),
    }
}
export default connect(null, mapDispatchToProps)(Dashboard);
