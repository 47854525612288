// import '../../assets/css/login/login2.css';
// import '../../assets/css/font-awesome/css/font-awesome.css';
// import '/assets/layout.css'
import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import * as actions from '../reducer/actions/index';
import styled from 'styled-components';


const FormLogin = styled.section`
    font-family: 'Roboto', sans-serif !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    // background-image: url('/assets/img/bg-login.png');
    // background-color: #dd1f26;

    h3{
        font-weight: bold;
    }

    .form-content{
        background-color: #dd1f26;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form{
        padding: 20px 10px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
    }

    .button-warning{
        background-color: #fff;
        color: #dd1f26;
        border: unset;
        font-weight: bolder;
    }

    .input-group-text{
        background-color: #fff;
        border-top-left-radius:0.25rem!important;
        border-bottom-left-radius:0.25rem!important;
        border: unset !important;
    }

    .input-group-text + .form-control{
        border-left: unset !important;
    }

    .form-control{
        border: unset !important;
        padding-left: 0 !important;
        font-family: 'Roboto', sans-serif !important;
    }

    .form-control:focus{
        outline: none;
        box-shadow: none;
        border: none
    }

    .input-group:focus-within {
        // border: 1px solid blue;
        color: #dd1f26
    }

    .input-group:focus-within .form-control{
        color: #dd1f26
    }

    .input-group:focus-within .fa{
        color: #dd1f26
    }


    // .form-control:focus-within{
    //     border: 2px solid #dd1f26;
    // }
`

const LoginPage = (props: any) => {
    // const isLoading = useSelector((x: any) => x.auth.isLoading);
    const dispatch = useDispatch();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onClickLogin = (e: any) => {
        if (username === "") {
            Swal.fire("Lỗi", "Không để trống tài khoản", "error");
            return false;
        }

        if (password === "") {
            Swal.fire("Lỗi", "Không để trống mật khẩu", "error");
            return false;
        }
        // console.log(username, password)
        dispatch(actions.login(username, password))
    }

    const onKeypress = (e: any) => {
        // console.log(`q`)
        if (e.which === 13) {
            let loginButton: any = document.querySelector(".login-buton");
            loginButton.click();
        }
    }

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };

    const onPasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    return (
        <FormLogin>
            <div className="container">
                <div className='row'>
                    <div className="col-sm-7">
                        <div className="d-flex flex-column align-items-center justify-content-between" style={{ height: `100%` }}>
                            {/* <img src="/assets/logo/coca.png" alt='Logo' style={{ width: 200, objectFit: "contain" }} />
                            <img src="/assets/img/product-2.jpg" alt='Logo' style={{ width: `100%`, objectFit: "contain" }} /> */}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="form-content">
                            <div className="form" style={{ width: '400px', height: '600px' }}>
                                <div style={{ width: `100%` }}>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ marginTop: 20 }}>
                                            <h3 style={{ color: `#fff` }}>THEMATIC</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ marginTop: 30 }}>
                                            <label className="sr-only">Tài khoản</label>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <i className="fa fa-user"></i>
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control username"
                                                    placeholder="Tài khoản"
                                                    value={username}
                                                    onChange={onUsernameChange}
                                                    onKeyPress={onKeypress}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ marginTop: 15 }}>
                                            <label className="sr-only">Mật khẩu</label>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <i className="fa fa-lock"></i>
                                                    </div>
                                                </div>
                                                <input
                                                    type="password"
                                                    className="form-control password"
                                                    placeholder="********"
                                                    value={password}
                                                    onChange={onPasswordChange}
                                                    onKeyPress={onKeypress}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ marginTop: 30 }}>
                                            <button className="btn btn-dark btn-block button-warning login-buton" onClick={onClickLogin}>
                                                ĐĂNG NHẬP
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FormLogin>
    )
}

export default LoginPage;