import { combineReducers } from 'redux';
import auth from './auth';
import filter from './filter';
import error from './error';
import breadcrumb from './breadcrumb'


const reducers = combineReducers({
    auth,
    filter,
    error,
    breadcrumb
});

export default reducers;