import React, { Fragment, useEffect, useState } from 'react';
import {
    listFilter
} from '../../components/filter/data/bill-accept';

import {
    configGrid,
    listColumn
} from '../../components/datatable/data/customer-code';
import DatatableComponent from '../../components/datatable/index2';
import DataView from '../../components/dataview/index2';
import { Button } from 'primereact/button';
import Lightbox from '../../components/Lightbox-component/index';
import styled from 'styled-components';
import * as router from "../../common/router";
import moment from 'moment';
import { useSelector } from 'react-redux';
const ItemImage = styled.div`
    width: 80px;
    height: 80px;
`
const ItemImageContent = styled.div`
    width: calc(100% - 80px);
    padding-left: 10px;
`

const Index = (props: any) => {
    const account: any = useSelector((x: any) => x.auth.profile);
    const [timeMod, setTimeMod] = useState(0);

    useEffect(() => {
        setTimeMod(moment().valueOf());
    }, [])


    const renderListItem = (item: any) => {
        // console.log(item);

        const image = [{
            src: item.linkImage,
            alt: item.socialId,
            title: item.socialId,
            description: ""
        }]

        return (
            <div className='col-12'>
                <div className="d-flex" style={{ padding: `10px 15px`, position: 'relative', overflowX: `hidden` }}>
                    <ItemImageContent>
                        <div className='container-fluid'>
                            <div className="row">
                                <div className='col-sm-4' style={{ marginTop: 10 }}>
                                    Người gửi: <b>{item.socialId}</b>
                                </div>
                                <div className='col-sm-4' style={{ marginTop: 10 }}>
                                    Tên Zalo: <b>{item.nameZalo}</b>
                                </div>
                                <div className='col-sm-4' style={{ marginTop: 10 }}>
                                    Ngày gửi: <b>{item.dateCreate}</b>
                                </div>
                               
                            </div>
                            <div className="row">
                                <div className='col-sm-4' style={{ marginTop: 10 }}>
                                    Trạng thái: <b>{item.nameStatus}</b>
                                </div>
                                {account.roleId !== 4 ?
                                <>  
                                    <div className='col-sm-4' style={{ marginTop: 10 }}>
                                    Người duyệt: <b>{item.nameUserAccept}</b>
                                    </div>
                                    <div className='col-sm-4' style={{ marginTop: 10 }}>
                                        Ngày duyệt: <b>{item.dateAccept}</b>
                                    </div>
                                </>
                               
                                 : null}
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <Button className="p-button p-component p-button-danger" label="Chi tiết" onClick={e=>detailRow(e,item)} />
                                </div>
                            </div>
                        </div>

                        {/* {JSON.stringify(item)} */}
                    </ItemImageContent>
                </div>
            </div>
        );
    }
    const detailRow = (e: any, row: any) => {
        e.preventDefault();
        props.history.push(`${router.DetailBillAccept}/${row.id}`)
    }


    const itemTemplate = (item: any, layout: string) => {
        if (item) {
            // console.log(item)
            if (layout === "list") {
                return renderListItem(item);
            }
        }
        return;
    }

    const cellTemplate = (row: any, cell: any) => {
        switch (cell.field) {
            case 'stt':
                return 1 + cell.rowIndex;
            case 'action':
                return (
                    <>
                    </>
                )
            default:
                return row[cell.field];
        }
    }


    return (
        <Fragment>
             <DatatableComponent
                header="Danh sách mã dự thưởng"
                columns={listColumn}
                filters={listFilter}
                config={configGrid}
                timeModify={timeMod}
                cellTemplate={cellTemplate}
            />
            {/* <DataView
                header='Danh sách mã dự thưởng'
                filters={listFilter}
                config={configGrid}
                itemTemplate={itemTemplate}
                timeUpdate={timeMod}
            /> */}
        </Fragment>
    );
}

export default Index;