import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
const Error =(props:any)=>{
    const toast = useRef<any>(null);
    useEffect(() => {
        if(props.error.severity !== null)
        {
            const{severity,summary,detail} = props.error;
   
            toast.current.show({severity: severity, summary: summary, detail: detail, life: 3000});
        }
    });

    return   <Toast ref={toast} position="top-right" />
    
}

const mapStateToProps = state => {
    return {
        error: state.error
    }
}

export default connect(mapStateToProps, null)(Error);
