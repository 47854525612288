import React, { useState, useEffect } from "react";
import * as api from '../../reducer/contains/api';
import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';
import * as enums from '../../common/enum';
import { Dropdown } from "primereact/dropdown";
import Swal from "sweetalert2";

import { func, number, string, bool } from 'prop-types';

import * as _ from 'lodash';

export const GroupMarket = ({ value, onChange, disabled }) => {
    // const dispatch = useDispatch();
    const [listMarket, setListMarket] = useState([]);

    useEffect(() => {
        const LoadMarket = async () => {
            await axiosWithHeaders('GET', `${api.LIST_SUPERMARKET}`, null)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            setListMarket(data.result);
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }

                }).catch(err => {
                    Swal.fire("Lỗi", err.toString(), "error");
                });

        }

        LoadMarket();
    }, [])

    // console.log(disabled)

    return (
        <>
            <Dropdown
                value={parseInt(value)}
                optionLabel="supermarketName"
                optionValue="supermarketId"
                options={listMarket}
                placeholder="Chọn hệ thống..."
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="supermarketId,supermarketName"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                disabled={disabled ? true: false}
                emptyMessage="Chưa có hệ thống nào !"
            />
        </>
    );
}

GroupMarket.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
    disabled: bool
}

export const SupermarketLocation = ({ value, onChange, disabled }) => {
    // const dispatch = useDispatch();
    const [listMarket, setListMarket] = useState([]);

    useEffect(() => {
        const LoadMarket = async () => {
            await axiosWithHeaders('GET', `${api.SUPERMARKET_LOCATION}`, null)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            let list: any = _.cloneDeep(data.result);
                            // console.log(list);
                            list.unshift({
                                supermarketLocationId: 0,
                                supermarketLocation: "Tất cả"
                            })
                            setListMarket(list);
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }

                }).catch(err => {
                    Swal.fire("Lỗi", err.toString(), "error");
                });

        }

        LoadMarket();
    }, [])

    // console.log(disabled)

    return (
        <>
            <Dropdown
                value={parseInt(value)}
                optionLabel="supermarketLocation"
                optionValue="supermarketLocationId"
                options={listMarket}
                placeholder="Chọn hệ thống..."
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="supermarketLocationId,supermarketLocation"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                disabled={disabled ? true: false}
                emptyMessage="Chưa có hệ thống nào !"
            />
        </>
    );
}

SupermarketLocation.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
    disabled: bool
}

export const GroupMarketFilter = ({ value, onChange }) => {
    // const dispatch = useDispatch();
    const [listMarket, setListMarket] = useState([]);

    useEffect(() => {
        const LoadMarket = async () => {
            await axiosWithHeaders('GET', `${api.LIST_SUPERMARKET_FULL}`, null)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            let list: any = _.cloneDeep(data.result);
                            // console.log(list);
                            list.unshift({
                                supermarketId: 0,
                                supermarketName: "Tất cả"
                            })
                            setListMarket(list);
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }

                }).catch(err => {
                    Swal.fire("Lỗi", err.toString(), "error");
                });

        }

        LoadMarket();
    }, [])



    return (
        <>
            <Dropdown
                value={parseInt(value)}
                optionLabel="supermarketName"
                optionValue="supermarketId"
                options={listMarket}
                placeholder="Chọn hệ thống..."
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="supermarketId,supermarketName"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có hệ thống nào !"
            />
        </>
    );
}

GroupMarketFilter.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
}

export const ListRole = ({ value, onChange, roleId }) => {
    const listRole = (roleId: number) => {
        if (roleId === 1)
            return [
                {
                    value: 1,
                    label: "Admin"
                },
                {
                    value: 2,
                    label: "Moderator"
                },
                {
                    value: 3,
                    label: "Call-Center"
                },
                {
                    value: 4,
                    label: "Client"
                }
            ]
        return [
            {
                value: 2,
                label: "Moderator"
            },
            {
                value: 3,
                label: "Call-Center"
            },
            {
                value: 4,
                label: "Client"
            }
        ]

    }


    return (
        <>
            <Dropdown
                value={value}
                options={listRole(roleId)}
                placeholder="Chọn phân quyền"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="value"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có phân quyền nào !"
            />
        </>
    );
}

ListRole.propTypes = {
    value: number.isRequired,
    onChange: func.isRequired,
    roleId: number.isRequired
}

export const ListProductMarket = ({ value, onChange, supermarketId, disabled }) => {
    // const dispatch = useDispatch();
    const [listAllProduct, setListAllProduct] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [currentId, setCurrentId] = useState(0);

    useEffect(() => {
        const LoadProductMarket = async () => {
            await axiosWithHeaders('GET', `${api.LIST_PRODUCT_MARKET}`, null)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            setListAllProduct(data.result);
                            // console.log(data.result)
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }

                }).catch(err => {
                    Swal.fire("Lỗi", err.toString(), "error");
                });

        }

        LoadProductMarket();
    }, [])

    useEffect(() => {
        if (supermarketId !== currentId) {
            const x = setTimeout(() => {
                let list: any = _.cloneDeep(listAllProduct);
                list = _.filter(list, (x: any) => {
                    return x.supermarketId === parseInt(supermarketId);
                });

                // console.log(supermarketId, list)

                setCurrentId(supermarketId);
                setListProduct(list);
                clearTimeout(x)
            }, 250)
        }
    }, [currentId, supermarketId, listAllProduct])

    return (
        <>
            <Dropdown
                value={value}
                options={listProduct}
                placeholder="Chọn giải thưởng"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="productName"
                optionLabel="productName"
                optionValue="productId"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                disabled={disabled ? disabled: false}
                emptyMessage="Chưa có giải thưởng nào !"
            />
        </>
    );
}

ListProductMarket.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
    supermarketId: string.isRequired,
    disabled: bool
}

export const ListProductMarketFilter = ({ value, onChange, supermarketId }) => {
    // const dispatch = useDispatch();
    const [listAllProduct, setListAllProduct] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [currentId, setCurrentId] = useState(0);

   // console.log(supermarketId)
    useEffect(() => {
        const LoadProductMarket = async () => {
            await axiosWithHeaders('GET', `${api.LIST_PRODUCT_MARKET}`, null)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            setListAllProduct(data.result);
                            // console.log(data.result)
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }

                }).catch(err => {
                    Swal.fire("Lỗi", err.toString(), "error");
                });

        }

        LoadProductMarket();
    }, [])

    useEffect(() => {
      //  if (supermarketId !== currentId) {
            let list: any = _.cloneDeep(listAllProduct);
            list = _.filter(list, (x: any) => {
                return x.supermarketId === parseInt(supermarketId);
            });

             console.log(supermarketId, list)

            let objAll = {
                productId: 0,
                productName: `Tất cả`
            }

            list.unshift(objAll);
           // setCurrentId(supermarketId);
            setListProduct(list);
       // }
    }, [ supermarketId, listAllProduct])

    return (
        <>
            <Dropdown
                value={value}
                options={listProduct}
                placeholder="Chọn giải thưởng"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="productName"
                optionLabel="productName"
                optionValue="productId"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có giải thưởng nào !"
            />
        </>
    );
}

ListProductMarketFilter.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
    supermarketId: string.isRequired
}

export const ListStatus = ({ value, onChange }) => {

    const data = [
        { value: 3, label: "Đã quay thưởng" },
        { value: 5, label: "Đã trao giải" },
        { value: 0, label: "Chờ xử lý" }
    ]

    return (
        <>
            <Dropdown
                value={value}
                options={data}
                placeholder="Chọn trạng thái"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="value"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có trạng thái nào !"
            />
        </>
    );
}

ListRole.propTypes = {
    value: number.isRequired,
    onChange: func.isRequired,
}

export const ListBillStatus = ({ value, onChange }) => {

    const data = [
        { value: "0", label: "Tất cả" },
        { value: "2", label: "Hóa đơn đã duyệt" },
        { value: "3", label: "Hóa đơn không duyệt" }
    ]

    return (
        <>
            <Dropdown
                value={value}
                options={data}
                placeholder="Chọn trạng thái"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="value"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có trạng thái nào !"
            />
        </>
    );
}

ListBillStatus.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
}


export const ListTypeStatus = ({ value, onChange }) => {

    const data = [
        { value: "0", label: "Tất cả" },
        { value: "1", label: "SMS" },
        { value: "2", label: "WEB" }
    ]

    return (
        <>
            <Dropdown
                value={value}
                options={data}
                placeholder="Chọn trạng thái"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="value"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có trạng thái nào !"
            />
        </>
    );
}

ListTypeStatus.propTypes = {
    value: string.isRequired,
    onChange: func.isRequired,
}

export const ListLucky = ({ value, onChange }) => {

    const data = [
        { value: 1, label: "Đợt 1" },
        { value: 2, label: "Đợt 2" },
        { value: 3, label: "Đợt 3" },
        { value: 0, label: "Tất cả" }
    ]

    return (
        <>
            <Dropdown
                value={value}
                options={data}
                placeholder="Chọn đợt quay giải"
                filter={true}
                filterPlaceholder="Tìm..."
                filterBy="value"
                filterMatchMode="contains"
                style={{ width: '100%', fontSize: 14 }}
                onChange={(e: any) => onChange(e)}
                emptyMessage="Chưa có đợt nào !"
            />
        </>
    );
}
// export default GroupMarket;