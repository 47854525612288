import React, { useState, useEffect } from 'react';
import { StyledBurger } from './button';
import { StyledMenu } from './nav';
import { StyledSideBar } from './sidebar'
import { bool, func } from 'prop-types';
import * as router from '../../common/router';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as config from "../../common/config";
import jwt_decode from "jwt-decode";
export const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};


export const MenuMobile = ({ open }) => {
    const location = useLocation();
    const account: any = useSelector((x: any) => x.auth.profile);
    const [pathName, setPathname] = useState('');
    const access_token: any = useSelector((x: any) => x.auth.token);
    const [userId, setUserId] = useState(0);
    useEffect(() => {
        if (access_token) {
          const userId = (jwt_decode(access_token) as any).userId;
          setUserId(parseInt(userId));
        }
        // console.log(userId, config.groupUser.includes(parseInt((jwt_decode(access_token) as any).userId)));
      }, [access_token]);
    useEffect(() => {
        setPathname(location.pathname)
    }, [location])

    return (
        <StyledMenu open={open}>
             {account && account.roleId === 1 && (
                <ul className='first-child'>
                    <li>
                        <Link to={router.Dashboard} className={(pathName !== "" && pathName === router.Dashboard) ? "active" : ""}>Tổng quan</Link>
                    </li>
   
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                   
                    <li>
                        <Link to={router.ListUserv2} className={(pathName !== "" && pathName === router.ListUserv2) ? "active" : ""}>Tài khoản</Link>
                    </li>
                    <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo </Link>
                    </li>
                    <li>
                        <Link to={router.Lucky} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số</Link>
                    </li>
                    <li>
                        <Link to={router.ResetLucky} className={(pathName !== "" && pathName === router.ResetLucky) ? "active" : ""}>Reset Quay số</Link>
                    </li>
                </ul>
            )}
            {(account && account.roleId === 2 && (
                <ul className='first-child'>
                    {/* <li>
                        <Link to={router.ListSocialv2} className={(pathName !== "" && pathName === router.ListSocialv2) ? "active" : ""}>Duyệt hóa đơn</Link>
                    </li> */}
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo </Link>
                    </li>
                </ul>
            ))}
            {(account && account.roleId === 3 && (
                <ul className='first-child'>
                    {/* <li>
                        <Link to={router.ListSocialv2} className={(pathName !== "" && pathName === router.ListSocialv2) ? "active" : ""}>Duyệt hóa đơn</Link>
                    </li> */}
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo </Link>
                    </li>
                    {/* <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li> */}
                    {/* <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo</Link>
                    </li> */}
                </ul>

            ))}
             {(account && account.roleId === 4 && (
                <ul className='first-child'>
                    {/* <li>
                        <Link to={router.Lucky} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số</Link>
                    </li>
                    <li>
                        <Link to={router.ResetLucky} className={(pathName !== "" && pathName === router.ResetLucky) ? "active" : ""}>Reset Quay số</Link>
                    </li> */}
                     <li>
                        <Link to={router.Dashboard} className={(pathName !== "" && pathName === router.Dashboard) ? "active" : ""}>Tổng quan</Link>
                    </li>
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo </Link>
                    </li>
                    {/* <li>
                        <Link to={router.ListFollower} className={(pathName !== "" && pathName === router.ListFollower) ? "active" : ""}>Người quan tâm</Link>
                    </li> */}
                </ul>

            ))}
        </StyledMenu>
    )
}

MenuMobile.propTypes = {
    open: bool.isRequired,
}

export const Menu = ({ open, setOpen }) => {

    const location = useLocation();
    const account: any = useSelector((x: any) => x.auth.profile);
    const access_token: any = useSelector((x: any) => x.auth.token);
    const [userId, setUserId] = useState(0);
    useEffect(() => {
        if (access_token) {
          const userId = (jwt_decode(access_token) as any).userId;
          setUserId(parseInt(userId));
        }
        // console.log(userId, config.groupUser.includes(parseInt((jwt_decode(access_token) as any).userId)));
      }, [access_token]);
    const [pathName, setPathname] = useState('');

    useEffect(() => {
        setPathname(location.pathname)
    }, [location])

    return (
        <StyledSideBar open={open}>
            {account && account.roleId === 1 && (
                <ul className='first-child'>
                    <li>
                        <Link to={router.Dashboard} className={(pathName !== "" && pathName === router.Dashboard) ? "active" : ""}>Tổng quan</Link>
                    </li>
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListUserv2} className={(pathName !== "" && pathName === router.ListUserv2) ? "active" : ""}>Tài khoản</Link>
                    </li>
                    <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                  <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportTurn} className={(pathName !== "" && pathName === router.ReportTurn) ? "active" : ""}>Báo cáo mã đợt quay </Link>
                    </li>
                    <li>
                    <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo tổng</Link>
                    </li>
                     {[1,2,48,52].some(id => id === userId) ? 
                        <>
                            <li>
                                <Link to={`${router.Lucky}?Turn=1`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 1</Link>
                            </li>
                            <li>
                                <Link to={`${router.Lucky}?Turn=2`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 2</Link>
                            </li>
                            <li>
                                <Link to={`${router.Lucky}?Turn=3`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 3</Link>
                            </li>
                            <li>
                                <Link to={router.ResetLucky} className={(pathName !== "" && pathName === router.ResetLucky) ? "active" : ""}>Reset Quay số</Link>
                            </li>
                            <li>
                                <Link to={router.FinishLucky} className={(pathName !== "" && pathName === router.FinishLucky) ? "active" : ""}>Chốt Quay số</Link>
                            </li>
                        </>
                    : null} 
                </ul>
            )}
            {(account && account.roleId === 2 && (
                <ul className='first-child'>
                    {/* <li>
                        <Link to={router.ListSocialv2} className={(pathName !== "" && pathName === router.ListSocialv2) ? "active" : ""}>Duyệt hóa đơn</Link>
                    </li> */}
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportTurn} className={(pathName !== "" && pathName === router.ReportTurn) ? "active" : ""}>Báo cáo mã đợt quay </Link>
                    </li>
                    {/* <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li> */}
                  <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                    <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo tổng</Link>
                    </li>
                </ul>
            ))}
            {(account && account.roleId === 3 && (
                <ul className='first-child'>
                    {/* <li>
                        <Link to={router.ListSocialv2} className={(pathName !== "" && pathName === router.ListSocialv2) ? "active" : ""}>Duyệt hóa đơn</Link>
                    </li> */}
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportTurn} className={(pathName !== "" && pathName === router.ReportTurn) ? "active" : ""}>Báo cáo mã đợt quay </Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo tổng</Link>
                    </li>
                    {/* <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                    <li>
                        <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo</Link>
                    </li> */}
                </ul>

            ))}
              {(account && account.roleId === 4 && (
                <ul className='first-child'>
                     {/* <li>
                        <Link to={router.Lucky} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số</Link>
                    </li>
                    <li>
                        <Link to={router.ResetLucky} className={(pathName !== "" && pathName === router.ResetLucky) ? "active" : ""}>Reset Quay số</Link>
                    </li> */}
                     <li>
                        <Link to={router.Dashboard} className={(pathName !== "" && pathName === router.Dashboard) ? "active" : ""}>Tổng quan</Link>
                    </li>
                    <li>
                        <Link to={router.ListBillAccept} className={(pathName !== "" && pathName === router.ListBillAccept) ? "active" : ""}>Mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ListCustomerv2} className={(pathName !== "" && pathName === router.ListCustomerv2) ? "active" : ""}>Người trúng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportCode} className={(pathName !== "" && pathName === router.ReportCode) ? "active" : ""}>Báo cáo mã dự thưởng</Link>
                    </li>
                    <li>
                        <Link to={router.ReportTurn} className={(pathName !== "" && pathName === router.ReportTurn) ? "active" : ""}>Báo cáo mã đợt quay </Link>
                    </li>
                    <li>
                            <Link to={router.ListReport} className={(pathName !== "" && pathName === router.ListReport) ? "active" : ""}>Báo cáo tổng</Link>
                    </li>
                    {/* <>
                            <li>
                                <Link to={`${router.Lucky}?Turn=1`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 1</Link>
                            </li>
                            <li>
                                <Link to={`${router.Lucky}?Turn=2`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 2</Link>
                            </li>
                            <li>
                                <Link to={`${router.Lucky}?Turn=3`} className={(pathName !== "" && pathName === router.Lucky) ? "active" : ""}>Quay số đợt 3</Link>
                            </li>
                            <li>
                                <Link to={router.ResetLucky} className={(pathName !== "" && pathName === router.ResetLucky) ? "active" : ""}>Reset Quay số</Link>
                            </li>
                    </> */}
                    {/* <li>
                        <Link to={router.ListFollower} className={(pathName !== "" && pathName === router.ListFollower) ? "active" : ""}>Người quan tâm</Link>
                    </li> */}
                </ul>

            ))}
{/* 
            <i className="pi pi-table" style={{position:'absolute',bottom:0}}  onClick={() => setOpen(!open)}></i> */}
        </StyledSideBar>
    )
}

Menu.propTypes = {
    open: bool.isRequired,
}
