import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    title: "",
    description: "",
    href: "/",
    icon: "",
    menuIndex: 0,
    parentIndex: 0,
    parentName: ""
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.BREADCRUMB_INI:
            return {
                ...state
            };
        case actionTypes.BREADCRUMB_CHANGE:
            return {
                title: action.title,
                href: action.href,
                icon: action.icon,
                description: action.description,
                menuIndex: action.menuIndex,
                parentIndex: action.parentIndex,
                parentName: action.parentName
            };
        case actionTypes.BREADCRUMB_CHANGE_DESC:
            return {
                ...state,
                description: action.description
            };
        default:
            return state;
    }
}

export default reducer;