import * as React from "react";
import { connect } from "react-redux";
// import { isMobile } from '../src/common/function';
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import Layout from "./layout/layout";
// import LayoutMobile from './layout/layoutMobile';
// import LayoutLogin from './layout/layoutLogin';
import * as router from "./common/router";
import Home from "./pages/Home/Home";
// import Support from "./pages/Home/Support";
import Page404 from "./pages/404";
import Login from "./pages/login";
//User
// import ListUser from './pages/User/ListUser';
import ListUserv2 from "./pages/User";
// import User from './pages/User/User';

// import ListCustomer from './pages/Customer/List';

import ListSocialv2 from "./pages/Social";
// import ListSocial from './pages/Social/List';
// import ListSocialAccept from './pages/Social/ListAccept';
import ListBillAccept from "./pages/BillAccept";
import Social from "./pages/Social/Social";
import SocialClone from "./pages/Social/SocialClone";
import DetailSocial from "./pages/Social/DetailSocial";

import AssignGift from "./pages/Assign";
// import ListGift from './pages/Gift/List';
import ListGroupStore from "./pages/StoreGroup";
import ListStore from "./pages/Store";
import ListCustomerv2 from "./pages/Customer";
import ListFollower from "./pages/Follower";
import BlackList from "./pages/BlackList";
import BlackListZalo from "./pages/BlackList/indexzalo";
import Report from "./pages/Report/index";
import ChangeProduct from "./pages/ChangeProduct";
import SetProduct from "./pages/SetProduct";
import ListPriority from "./pages/Product";
import ListProductMessage from "./pages/ProductMessage";
import Bill from "./pages/SetProduct/bill";
import Set from "./pages/SetProduct/set";
import Dashboard from "./pages/Home/Dashboard";
import Lucky from "./pages/Lucky";
import ResetLucky from "./pages/Lucky/Reset";
import FinishLucky from "./pages/Lucky/Finish";
import ReportCode from "./pages/Report/ReportCode";
import ReportTurn from "./pages/Report/ReportTurn";
function RootComponent(props: any) {
  let route = (
    <Switch>
      <Route path={router.Login} component={Login} />
      <Redirect to={router.Login} />
    </Switch>
  );

  if (props.isAuth) {
    route = (
      <Layout>
        <Switch>
          <Route exact path={router.Home} component={Home} />
          <Route path={router.Page404} component={Page404} />
          <Redirect to={router.Home} />
        </Switch>
      </Layout>
    );
    const { roleId } = props.profile;
    switch (roleId) {
      case 1:
        route = (
          <Switch>
            <Route exact path={router.Lucky} component={Lucky} />
           
          
            <Route>
              <Layout>
                <Switch>
                  <Route exact path={router.Dashboard} component={Dashboard} />
                  <Route
                    exact
                    path={router.ListSocialv2}
                    component={ListSocialv2}
                  />
                  <Route
                    exact
                    path={router.ListBillAccept}
                    component={ListBillAccept}
                  />
                  <Route
                    exact
                    path={router.ListUserv2}
                    component={ListUserv2}
                  />
                  <Route
                    exact
                    path={router.ListCustomerv2}
                    component={ListCustomerv2}
                  />
                  <Route exact path={router.ListReport} component={Report} />
                  <Route exact path={router.ReportCode} component={ReportCode} />
                  <Route exact path={router.ReportTurn} component={ReportTurn} />
                  <Route
                    exact
                    path={`${router.DetailBillAccept}/:id`}
                    render={(props) => <DetailSocial {...props} />}
                  />
                  <Route exact path={router.FinishLucky} component={FinishLucky} />
                  <Route exact path={router.ResetLucky} component={ResetLucky} />
                  {/* <Route
                    exact
                    path={router.ResetLucky}
                    component={ResetLucky}
                  /> */}
                  {/* <Route exact path={router.SetProductTai} component={Set} /> */}
                  <Route path={router.Page404} component={Page404} />
                  <Redirect to={router.Dashboard} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        );

        break;
      case 2:
        route = (
          <Layout>
            <Switch>
              <Route
                exact
                path={router.ListSocialv2}
                component={ListSocialv2}
              />
      
              <Route
                exact
                path={router.ListBillAccept}
                component={ListBillAccept}
              />
              <Route
                exact
                path={router.ListCustomerv2}
                component={ListCustomerv2}
              />
                               <Route exact path={router.ListReport} component={Report} />
                  <Route exact path={router.ReportCode} component={ReportCode} />
                  <Route exact path={router.ReportTurn} component={ReportTurn} />
              <Route
                exact
                path={`${router.DetailBillAccept}/:id`}
                render={(props) => <DetailSocial {...props} />}
              />
       
              <Route path={router.Page404} component={Page404} />
              <Redirect to={router.ListSocialv2} />
            </Switch>
          </Layout>
        );

        break;
      case 3:
        route = (
          <Layout>
            <Switch>  
              <Route
                exact
                path={router.ListSocialv2}
                component={ListSocialv2}
              />
           
              <Route
                exact
                path={router.ListBillAccept}
                component={ListBillAccept}
              />
              <Route
                exact
                path={router.ListCustomerv2}
                component={ListCustomerv2}
              />
              <Route
                exact
                path={router.ListFollower}
                component={ListFollower}
              />
              <Route
                exact
                path={`${router.DetailBillAccept}/:id`}
                render={(props) => <DetailSocial {...props} />}
              />
                            <Route exact path={router.ListReport} component={Report} />
                  <Route exact path={router.ReportCode} component={ReportCode} />
                  <Route exact path={router.ReportTurn} component={ReportTurn} />
              <Route path={router.Page404} component={Page404} />
              <Redirect to={router.ListSocialv2} />
            </Switch>
          </Layout>
        );
        //  console.log(router);
        break;
      case 4:
        route = (
          <Layout>
            <Switch>
              <Route exact path={router.Dashboard} component={Dashboard} />
              <Route
                exact
                path={router.ListBillAccept}
                component={ListBillAccept}
              />
              <Route
                exact
                path={router.ListCustomerv2}
                component={ListCustomerv2}
              />
              <Route
                exact
                path={router.ListFollower}
                component={ListFollower}
              />
              <Route
                exact
                path={`${router.DetailBillAccept}/:id`}
                render={(props) => <DetailSocial {...props} />}
              />
                             <Route exact path={router.ListReport} component={Report} />
                  <Route exact path={router.ReportCode} component={ReportCode} />
                  <Route exact path={router.ReportTurn} component={ReportTurn} />
              <Redirect to={router.ResetLucky} />
            </Switch>
          </Layout>
        );
        break;
      //}
    }
  }
  return <>{route}</>;
}

const mapStateToProps = (state: any) => {
  //  console.log(store)
  return {
    isAuth: state.auth.token !== null,
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps, null)(withRouter(RootComponent));
