
export const listFilter = [
    // {
    //     field: 'supermarketId',
    //     title: 'Hệ thống siêu thị',
    //     type: 'dropdown',
    //     value: '1',
    // //    component: 'market-group',
    //   //  sort: 0
    // },
    // {
    //     field: 'supermarketLocationId',
    //     title: 'Siêu thị',
    //     type: 'dropdown',
    //     value: '',
    //     component: 'market-location',
    //     sort: 1
    // },
    {
        field: 'productId',
        title: 'Giải thưởng',
        type: 'dropdown',
        value: '0',
        component: 'market-product',
        sort: 0
    },
    {
        field: 'status',
        title: 'Đợt',
        type: 'dropdown',
        value: 0,
        component: 'turnlucky',
        sort: 0
    },
    {
        field: 'search',
        title: 'Từ khóa',
        type: 'string',
        value: '',
        component: '',
        sort: 1
    }    
]