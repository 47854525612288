import React, { useState, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';

import * as enums from '../../common/enum';

import * as DropdownList from '../filter/list-dropdown';
import * as AutocompleteList from '../filter/list-autocomplete';

import { func, object, array, number, string } from 'prop-types';
import { Calendar } from 'primereact/calendar';

import styled from 'styled-components';
import * as _ from 'lodash';
import moment from 'moment';
// import { ColumnGroup } from 'primereact/columngroup';
import Swal from 'sweetalert2';
// import moment from 'moment';

const FilterComponent = styled.section`
    .p-dropdown .p-dropdown-trigger{
        width: 1.75rem;
    }
`
const DataTableComponent = styled.section`
    position: relative;
    border-collapse: collapse;

    .p-datatable-responsive-scroll>.p-datatable-wrapper>table, .p-datatable-auto-layout>.p-datatable-wrapper>table{
        table-layout: fixed
    }

    .p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-tbody > tr > td{
        padding: 2.5px;
        width: 100%;
        font-size: 12px;
    }

    .p-paginator-right-content{
        font-size: 12px;
    }

    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 5px;
    }

    .p-button.p-button-icon-only{
        width: 1.875rem;
    }

    .p-datatable-emptymessage > td {
        justify-content: center !important;
    }

    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
        min-width: 1rem; 
        height: 1rem;
    }

    // .p-datatable-scrollable-both .p-datatable-thead>tr>th, .p-datatable-scrollable-both .p-datatable-tbody>tr>td, .p-datatable-scrollable-both .p-datatable-tfoot>tr>td, .p-datatable-scrollable-horizontal .p-datatable-thead>tr>th .p-datatable-scrollable-horizontal .p-datatable-tbody>tr>td, .p-datatable-scrollable-horizontal .p-datatable-tfoot>tr>td{
    //     flex: 1 1 auto;
    // }

    // .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td{
    //     border-width: 0;
    //     border-left-width: 1px;
    //     border-right-width: 1px;
    // }
`

const Index = ({ columns, filters, config, header, onAdd, value, cellTemplate, headerGroup, footerGroup, timeModify, renderItemColumn,updateFilter }) => {
   // const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);
    const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);
    // const [errorMessage, setErrorMessage] = useState<string>('')
    const [filterData, setFilterData] = useState<any>(null);
    const [data, setData] = useState<any>([]);
    const [rows, setRows] = useState<number>(50);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [first, setFirst] = useState<number>(0);
    const [paginator, setPaginator] = useState<boolean>(true);
    const [, setPageNo] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [columnGroup, setColumnGroup] = useState<any>([]);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        let obj: any = {};
        filters.forEach((item: any) => {
            obj[item.field] = item.value;
        })
        setFilterData(obj);
    }, [filters])

    useEffect(() => {
        if (value && value.length > 0) {
            setData(value);
            setTotalRecords(value.length);
            setPaginator(false);
        }
    }, [value]);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize);

        return (() => {
            window.removeEventListener('resize', handleResize)
        });
    }, []);

    useEffect(() => {
        if (timeModify && timeModify !== currentTime) {

            let obj: any = {};
            filters.forEach((item: any) => {
                obj[item.field] = item.value;
                if (item.field === "supermarketLocationId") {
                    obj[item.field] = "0";
                }
            })
            //console.log("TEST OBJECT")
            //console.log(obj)
            getGridData(0, 50, obj);
            setCurrentTime(timeModify);
        }
    }, [timeModify, currentTime])

    const onFilterChange = (e: any, field: string, type: string) => {
        let newValue = _.cloneDeep(filterData);

        switch (type) {
            case 'dropdown':
            case 'autocomplete':
                newValue[field] = e.value;
                break;
            default:
                newValue[field] = e.target.value;
                break;
        }
        setFilterData(newValue);
    }
    const SearchData=()=>{
        getGridData(0, rows, filterData)
        if (typeof updateFilter === "function") { 
            //console.log(filterData)
            var newFilter = filters
            newFilter.forEach((item: any) => {
                //console.log(item)
                item.value =  filterData[item.field]
                //obj[item.field] = item.value;
                return item
            })
           // console.log(newFilter)
            updateFilter(newFilter)
            // safe to use the function
        }
    }

    const renderFilterItem = () => {
        let html: any = []

        if (filters.length > 0 && filterData) {
            filters.forEach((item: any, index: number) => {
                switch (item.type) {
                    case 'dropdown':
                        switch (item.component) {
                            case 'market-group':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.GroupMarket value={filterData ? filterData[item.field].toString() : '0'} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'market-location':
                                    html.push(
                                        <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <b>{item.title} (*)</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <DropdownList.SupermarketLocation value={filterData ? filterData[item.field].toString() : '0'} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    break;
                            case 'market-product':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListProductMarketFilter value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} supermarketId={"1"} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'bill-status':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListBillStatus value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'type-status':
                                    html.push(
                                        <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <b>{item.title} (*)</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <DropdownList.ListTypeStatus value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    break;
                            case 'status':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <span>{item.title}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListStatus value={filterData[item.field]} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'turnlucky':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <span>{item.title}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListLucky value={filterData[item.field]} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'autocomplete':
                        switch (item.component) {
                            case 'market-location':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <AutocompleteList.MarketLocation value={filterData ? filterData[item.field] : ""} onChange={(e: any) => onFilterChange(e, item.field, item.type)} supermarketId={filterData["supermarketId"]} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        html.push(
                            <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                <div className="row">
                                    <div className='col-md-12' style={{ marginTop: 10 }}>
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-12' style={{ marginTop: 10 }}>
                                        <input
                                            placeholder={`Nhập ` + item.title.toLowerCase()}
                                            className='p-component p-inputtext'
                                            value={filterData ? filterData[item.field] : ""}
                                            style={{ width: "100%" }}
                                            onChange={(e: any) => onFilterChange(e, item.field, item.type)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                        break;
                }
            })

            if (config.useDateRange) {
                html.push(
                    <div className='col-md-4 col-lg-2' key={`from_date-1`}>
                        <div className="row">
                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                <span>Từ ngày - Đến ngày</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                <Calendar
                                    style={{ width: `100%` }}
                                    value={dateRange}
                                    onChange={(e: any) => setDateRange(e.value)}
                                    selectionMode="range"
                                    dateFormat='dd/mm/yy'
                                    readOnlyInput
                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                />
                            </div>
                        </div>
                    </div>
                )
            }

            html.push(
                <div className='col-md-4 col-lg-2' key={`action-button`}>
                    <div className="row">
                        <div className='col-md-12' style={{ marginTop: 10 }}>
                            <span>&nbsp;</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12' style={{ marginTop: 10 }}>
                            <Button label="Tìm kiếm" className='p-button-warning' loading={loading} onClick={() => SearchData()} />
                        </div>
                    </div>
                </div>
            )

            return (
                <FilterComponent className='row'>
                    <div className='col-md-12'>
                        <div className="card" style={{ marginBottom: 20, marginTop: 10 }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span style={{ fontSize: 18, fontWeight: 500 }}>Bộ lọc</span>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: 20 }}>
                                    {html}
                                </div>
                                {/* <div className='row' style={{ paddingTop: 5 }}>
                                    {errorMessage !== "" && (
                                        <span style={{ fontSize: 14, color: `` }}>{errorMessage}</span>
                                    )}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </FilterComponent>
            )
        }
        return null;
    }

    const getGridData = async (page: number, rows: number, objFilter: any) => {


        let params: any = {
            ...objFilter,
            // pageNo: page + 1,
            // pageRows: rows
        }


        for (var propertyName in objFilter) {
            switch (typeof (objFilter[propertyName])) {
                case 'object':
                    params[propertyName] = (objFilter[propertyName][propertyName] === "") ? "0" : objFilter[propertyName][propertyName];
                    break;
                default:
                    params[propertyName] = objFilter[propertyName]
                    if (propertyName === "supermarketLocationId") {
                        params[propertyName] = (objFilter[propertyName] === "") ? "0" : objFilter[propertyName];
                    }
                    break;
            }
        }


        if (config.useDateRange) {
            let dateFrom: any = "";
            let dateTo: any = "";

            if (dateRange) {
                dateFrom = dateRange[0];
                dateTo = dateRange[1];

                if (!dateFrom) {
                    // setErrorMessage('Vui lòng chọn ngày bắt đầu')
                    return;
                }

                if (!dateTo) {
                    return;
                }
            }

            params = {
                ...params,
                dateFrom: (dateFrom) ? moment(dateFrom).format('YYYYMMDD') : "",
                dateTo: (dateTo) ? moment(dateTo).format('YYYYMMDD') : "",
                // pageNo: page + 1,
                // pageRows: rows,
            }
        }



        params = {
            ...params,
            pageNo: page + 1,
            pageRows: rows,
        }

        // console.log(JSON.stringify(params), config.api);

        setLoading(true);

        await axiosWithHeaders("post", config.api, params)
            .then((response: any) => {
                if (response && response.status === enums.STATUS_RESPONSE_OK) {
                    // console.log(response);

                    const { data } = response;
                    if (data && data.status === enums.STATUS_RESPONSE_OK) {
                        setData(data.result);
                        // console.log(data.result);
                        data.headers && setColumnGroup(data.headers);
                        setTotalRecords(data.result[0].countRow)
                        setFirst(page * rows);
                        setPageNo(page);
                        setRows(rows);
                    } else {
                        setData([]);
                        setColumnGroup([]);
                        setTotalRecords(0)
                        setFirst(0);
                        setPageNo(0);
                        setRows(0);
                        Swal.fire("Lỗi", data.message, "error")
                    }
                } else {
                    setData([]);
                    setTotalRecords(0)
                    setFirst(0);
                    setPageNo(0);
                    setRows(0);
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onPage = async (e: any) => {
        await getGridData(e.page, e.rows, filterData);
        if (typeof updateFilter === "function") { 
           // updateFilter(filterData)
            // safe to use the function
        }
    }

    const renderColumnsItem = () => {
        let html: any = [];
        if (columns.length > 0) {
            columns.forEach((item: any, index: number) => {
                html.push(
                    <Column
                        key={`th-${index}`}
                        field={item.field}
                        header={item.title}
                        headerStyle={{
                            ...item.headerStyle
                        }}
                        style={{
                            ...item.style,
                            width: item.width,
                            flexBasis: item.width,
                            flexGrow: (item.width / 150)
                        }}
                        body={cellTemplate}
                    // sortable
                    // sortable
                    />
                )
            });
            return html;
        }
        return null;
    }

    return (
        <>
            {header && (
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-center justify-content-between" style={{ width: `100%`, borderBottom: `1px solid #f4f4f4`, paddingBottom: `10px` }}>
                            <span style={{ fontSize: 24, fontWeight: 500 }}>{header}</span>
                            {config && config.allowAdd && (
                                // <button className="p-button p-button-danger" onClick={onAdd}>
                                //     {config.titleAdd}
                                // </button>
                                <Button icon="pi pi-plus" label='Thêm' className='p-button-sm p-button-success' onClick={() => onAdd({ ...filterData, dateRange: (config.useDateRange) ? _.cloneDeep(dateRange) : null })} />
                            )}
                        </div>
                    </div>
                </div>
            )}
            {renderFilterItem()}
            <div className="row">
                <div className='col-md-12'>
                    <DataTableComponent>
                        <DataTable
                            headerColumnGroup={columnGroup.length > 0 ? headerGroup(columnGroup, data) : null}
                            footerColumnGroup={footerGroup}
                            value={data}
                            rows={rows}
                            paginator={paginator}
                            totalRecords={totalRecords}
                            scrollable
                            scrollHeight={dimensions ? `${dimensions.height - 365}px` : `400px`}
                            scrollDirection="both"
                            loading={loading}
                            // scrol={500}
                            lazy={true}
                            first={first}
                            onPage={onPage}
                            stripedRows
                            showGridlines={config && config.showGridlines ? true : false}
                            // responsiveLayout="scroll"
                            // className="p-datatable-sm"
                            responsiveLayout="scroll"
                            // rowsPerPageOptions={configGrid.rowsPerPageOptions}
                            // paginatorTemplate={paginatorTemplate}
                            emptyMessage="Không có dữ liệu"
                            pageLinkSize={3}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            paginatorLeft={` `}
                            paginatorRight={
                                <span>Tổng cộng <b style={{ color: `red` }}>{totalRecords}</b> dòng.</span>
                            }
                        >
                            {columnGroup.length > 0 ? renderItemColumn(columns, columnGroup) : renderColumnsItem()}
                        </DataTable>
                    </DataTableComponent>
                </div>
            </div>
        </>
    );
}

export default Index;

Index.propTypes = {
    // value: array.isRequired,
    // rows: number.isRequired,
    // itemTemplate: func.isRequired,
    // totalRecords: number.isRequired,
    // first: number.isRequired,
    // onPage: func.isRequired,
    // loading: bool.isRequired,
    columns: array.isRequired,
    filters: array.isRequired,
    config: object.isRequired,
    header: string.isRequired,
    onAdd: func,
    value: array,
    cellTemplate: func,
    headerGroup: func,
    footerGroup: func,
    timeModify: number,
    renderItemColumn: func,
    updateFilter: func,
}



