import * as api from "../../../reducer/contains/api";

export const listColumn = [
  {
    field: "stt",
    title: "#",
    width: 60,
  },
  {
    field: "codeLink",
    title: "Mã dự thưởng",
    width: 80,
  },
  // {
  //   field: "name",
  //   title: "Khách hàng",
  //   width: 150,
  // },
  {
    field: "phone",
    title: "Số điện thoại",
    width: 80,
  },
  // {
  //   field: "code",
  //   title: "CMND/CCCD",
  //   width: 80,
  // },
  // {
  //   field: "address",
  //   title: "Địa chỉ",
  //   width: 200,
  // },
  {
    field: "productName",
    title: "Giải thưởng",
    width: 100,
  },
  {
    field: "nameStatus",
    title: "ĐỢT",
    width: 100,
  },
  {
    field: "dateLucky",
    title: "Ngày trúng",
    width: 100,
  },
  {
    field: "action",
    headerStyle: {
      textAlign: `center`,
    },
    style: {
      justifyContent: `center`,
    },
    // title: "Ngày kết thúc",
    width: 50,
  },
];

export const configGrid = {
 // useDateRange: true,
  allowAdd: false,
  titleAdd: "Thêm hệ thống",
  api: api.CUSTOMER_FILTER,
  // useColNo: true,
  defaultFilter: {
    supermarketId: 0,
    productId: 0,
    status: 0,
    search: "",
  },
};
