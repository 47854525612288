import React, { useState, useEffect, useMemo } from 'react';
import { DataView } from 'primereact/dataview';
import { func, array, object, string } from 'prop-types';
import styled from 'styled-components';
import { Calendar } from 'primereact/calendar';
import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';
import { Button } from 'primereact/button';
import * as enums from '../../common/enum';
import * as _ from 'lodash';
import * as DropdownList from '../filter/list-dropdown';
import * as AutocompleteList from '../filter/list-autocomplete';
import moment from 'moment';

const FilterComponent = styled.section`
    margin-bottom: 10px;

    .p-dropdown .p-dropdown-trigger{
        width: 1.75rem;
    }
`;

const DataViewComponent = styled.div`
    .p-dataview-content{
        max-height: ${({ height }) => height ? `${height}px` : '400px'};
        overflow-y: scroll;
        border-top-left-radius:15px!important;
        border-top-right-radius:15px!important;
        // border-width: 1px 1px 0px 1px;
        // border-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        // padding: 5px 10px;
    }

    .p-dataview-content::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .p-dataview-content::-webkit-scrollbar
    {
        width: 8px;
        background-color: #F5F5F5;
    }

    .p-dataview-content::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
    }

    .p-paginator{
        border-bottom-left-radius:15px!important;
        border-bottom-right-radius:15px!important;
        border: 1px solid #f0f0f0;
        border-top: unset;
        padding: 10px;
    }

    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div{
        border-width: 0
    }

    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > .col-12:first-child{
        // margin-top: 10px;
    }

    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > .col-12:nth-child(odd) {
        background-color: rgba(0,0,0,.05)
    }
    
    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > .col-12:nth-child(even) {
        background: transparent;
    }
`

const Index = ({ value, itemTemplate, timeUpdate, header, config, filters, onAdd }) => {

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);
    const [filterData, setFilterData] = useState<any>(null);

    const [rows, setRows] = useState<number>(50);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [first, setFirst] = useState<number>(0);

    const [data, setData] = useState([]);
    const [timeModify, setTimeModify] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNo, setPageNo] = useState<number>(0);

    useEffect(() => {
        // console.log(filters);
        let obj: any = {};
        filters.forEach((item: any) => {
            obj[item.field] = item.value;
        })
        // console.log(obj);
        setFilterData(obj);
    }, [filters]);

    useEffect(() => {
        if (timeModify !== timeUpdate) {
            getGridData(pageNo, rows, filterData);
            setTimeModify(timeUpdate)
        }
    }, [timeModify, timeUpdate])

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize);

        return (() => {
            window.removeEventListener('resize', handleResize)
        });
    }, []);

    const onFilterChange = (e: any, field: string, type: string) => {
        // console.log(e, field)
        let newValue = _.cloneDeep(filterData);

        switch (type) {
            case 'dropdown':
                newValue[field] = e.value;
                break;
            default:
                newValue[field] = e.target.value;
                break;
        }

        // console.log(values);

        setFilterData(newValue);
    }

    const renderFilterItem = () => {
        let html: any = []
        if (filters.length > 0 && filterData) {
            filters.forEach((item: any, index: number) => {
                switch (item.type) {
                    case 'dropdown':
                        switch (item.component) {
                            case 'market-group':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.GroupMarket value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'market-location':
                                    html.push(
                                        <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <b>{item.title} (*)</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <DropdownList.SupermarketLocation value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    break;
                            case 'market-product':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListProductMarketFilter value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} supermarketId={filterData ? filterData["supermarketId"] : "1"} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'bill-status':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <b>{item.title} (*)</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListBillStatus value={filterData ? filterData[item.field] : "0"} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case 'status':
                                html.push(
                                    <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <span>{item.title}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <DropdownList.ListStatus value={filterData[item.field]} onChange={(e: any) => onFilterChange(e, item.field, item.type)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'autocomplete':
                            switch (item.component) {
                                case 'market-location':
                                    html.push(
                                        <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <b>{item.title} (*)</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-md-12' style={{ marginTop: 10 }}>
                                                    <AutocompleteList.MarketLocation value={filterData ? filterData[item.field] : ""} onChange={(e: any) => onFilterChange(e, item.field, item.type)} supermarketId={filterData["supermarketId"]} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    break;
                                default:
                                    break;
                            }
                            break;
                    default:
                        html.push(
                            <div className='col-md-4 col-lg-2' key={`${item.component}-${index}`}>
                                <div className="row">
                                    <div className='col-md-12' style={{ marginTop: 10 }}>
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-12' style={{ marginTop: 10 }}>
                                        <input
                                            placeholder={`Nhập ` + item.title.toLowerCase()}
                                            className='p-component p-inputtext'
                                            value={filterData ? filterData[item.field] : ""}
                                            style={{ width: "100%" }}
                                            onChange={(e: any) => onFilterChange(e, item.field, item.type)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                        break;
                }
            })

            if (config.useDateRange) {
                html.push(
                    <div className='col-md-4 col-lg-2' key={`from_date-3`}>
                        <div className="row">
                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                <span>Từ ngày - Đến ngày</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                <Calendar
                                    style={{ width: `100%` }}
                                    value={dateRange}
                                    onChange={(e: any) => setDateRange(e.value)}
                                    selectionMode="range"
                                    dateFormat='dd/mm/yy'
                                    // readOnlyInput
                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                />
                                {/* <Calendar value={dateRange}
                                    onChange={(e: any) => setDateRange(e.value)} selectionMode="range" readOnlyInput /> */}
                            </div>
                        </div>
                    </div>
                )
            }

            html.push(
                <div className='col-md-4 col-lg-2' key={`action-button`}>
                    <div className="row">
                        <div className='col-md-12' style={{ marginTop: 10 }}>
                            <span>&nbsp;</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12' style={{ marginTop: 10 }}>
                            <Button label="Tìm kiếm" className='p-button-warning' loading={loading} onClick={() => getGridData(0, rows, filterData)} />
                        </div>
                    </div>
                </div>
            )

            return (
                <FilterComponent className='row'>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span style={{ fontSize: 18, fontWeight: 500 }}>Bộ lọc</span>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: 20 }}>
                                    {html}
                                </div>
                            </div>
                        </div>
                    </div>
                </FilterComponent>
            )
        }
        return null;
    }


    const getGridData = async (page: number, rows: number, objFilter: any) => {


        let params: any = {
            ...objFilter,
            // pageNo: page + 1,
            // pageRows: rows
        }


        for (var propertyName in objFilter) {
            switch (typeof (objFilter[propertyName])) {
                case 'object':
                    params[propertyName] = (objFilter[propertyName][propertyName] === "") ? "0" : objFilter[propertyName][propertyName];
                    break;
                default:
                    params[propertyName] = objFilter[propertyName]
                    if (propertyName === "supermarketLocationId") {
                        params[propertyName] = (objFilter[propertyName] === "") ? "0" : objFilter[propertyName];
                    }
                    break;
            }
        }


        if (config.useDateRange) {
            let dateFrom: any = "";
            let dateTo: any = "";

            if (dateRange) {
                dateFrom = dateRange[0];
                dateTo = dateRange[1];

                if (!dateFrom) {
                    // setErrorMessage('Vui lòng chọn ngày bắt đầu')
                    return;
                }

                if (!dateTo) {
                    return;
                }
            }

            params = {
                ...params,
                dateFrom: (dateFrom) ? moment(dateFrom).format('YYYYMMDD') : "",
                dateTo: (dateTo) ? moment(dateTo).format('YYYYMMDD') : "",
                // pageNo: page + 1,
                // pageRows: rows,
            }
        }



        params = {
            ...params,
            pageNo: page + 1,
            pageRows: rows,
        }

        // console.log(params, config.api);

        setLoading(true);

        await axiosWithHeaders("post", config.api, params)
            .then((response: any) => {
                if (response && response.status === enums.STATUS_RESPONSE_OK) {
                    const { data } = response;
                    if (data && data.status === enums.STATUS_RESPONSE_OK) {
                        setData(data.result);
                        // console.log(data.result)
                        if(data.result != undefined && data.result != null && data.result.length > 0){
                            setTotalRecords(data.result[0].countRow)
                            setFirst(page * rows);
                            setPageNo(page);
                            setRows(rows);
                        }
                        else{
                            setTotalRecords(0)
                            setFirst(page * rows);
                            setPageNo(page);
                            setRows(rows);
                        }
                       
                    } else {
                        setData([]);
                        setTotalRecords(0)
                        setFirst(0);
                        setPageNo(0);
                        setRows(0);
                    }
                } else {
                    setData([]);
                    setTotalRecords(0)
                    setFirst(0);
                    setPageNo(0);
                    setRows(0);
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onPage = async (e: any) => {
        // console.log(e);
        await getGridData(e.page, e.rows, filterData);
    }

    return (
        <>
            {header && (
                <div className='row'>
                    <div className='col-12'>
                        <div className="d-flex align-items-center justify-content-between" style={{ width: `100%`, borderBottom: `1px solid #f4f4f4`, paddingBottom: `10px` }}>
                            <span style={{ fontSize: 24, fontWeight: 500 }}>{header}</span>
                            {config && config.allowAdd && (
                                // <button className="p-button p-button-danger" onClick={onAdd}>
                                //     {config.titleAdd}
                                // </button>
                                <Button icon="pi pi-plus" label='Thêm' className='p-button-sm p-button-success' onClick={onAdd} />
                            )}
                        </div>
                    </div>
                </div>
            )}
            {renderFilterItem()}
            <DataViewComponent height={dimensions ? (dimensions.height - (filters.length > 0 ? 365 : 205)) : 400}>
                <DataView
                    value={data}
                    layout={"list"}
                    itemTemplate={itemTemplate}
                    lazy
                    paginator
                    rows={rows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={onPage}
                    loading={loading}
                    pageLinkSize={3}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    paginatorLeft={` `}
                    paginatorRight={
                        <span>Tổng cộng <b style={{ color: `red` }}>{totalRecords}</b> dòng.</span>
                    }
                    currentPageReportTemplate="Hiển thị {first} đến {last} trong tổng số {totalRecords} dòng"
                />
            </DataViewComponent>
        </>


    );
}

export default Index;

Index.propTypes = {
    value: object,
    itemTemplate: func.isRequired,
    filters: array.isRequired,
    config: object.isRequired,
    header: string.isRequired,
    onAdd: func
}