const dotenv = require("dotenv");
dotenv.config();
// capture the environment variables the application needs
export const {
  REACT_APP_PAGE_SIZE,
  REACT_APP_MAX_WIDTH,
  REACT_APP_MAX_HEIGHT,
  REACT_APP_SECRET_KEY,
  REACT_APP_SECRET_KEY_CLIENT,
  REACT_APP_JWT,
  REACT_APP_JWT_REFESH,
  REACT_APP_JWT_PROFILE,
} = process.env;

export function GetURL() {
  var enviroment = 1;
  var baseUrlLink = "https://localhost:44348";
  switch (enviroment) {
    case 0:
      baseUrlLink = "https://localhost:44348";
      break;
    case 1:
      baseUrlLink = "https://api-tetvuitrunglon.tcgh.com.vn";
      break;
    case 3:
      baseUrlLink = "http://localhost:29838";
      break;
    default:
      baseUrlLink = "";
      break;
  }
  return baseUrlLink;
}

export function GetURLReport() {
  var enviroment = 1;
  var baseUrlLink = "https://localhost:44348";
  switch (enviroment) {
    case 0:
      baseUrlLink = "https://localhost:44348";
      break;
    case 1:
      baseUrlLink = "https://api-tetvuitrunglon.tcgh.com.vn";
      break;
    default:
      baseUrlLink = "";
      break;
  }
  return baseUrlLink;
}
export const SERVICE_URI = GetURL();

export const groupUser = [2, 8, 9];
