import '../assets/css/custom/404.css'
import * as React from 'react';
import {
    Link
} from "react-router-dom";
import * as router from '../common/router';

const page404 = () =>{
    return (
        <div className="row">
            <div className="col-md-12 text-center">
                <h1>404</h1>
                <h4>Page Not Found</h4>
                <p>It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track.</p>
                <Link to={router.Home} style={{color: "#fff"}}>Return to Home</Link>
            </div>
        </div>
    )
}

export default page404;