import React, { Fragment, useState, useEffect, useRef } from "react";
import {
    listFilterUser
} from '../../components/filter/data/user';

import { Button } from 'primereact/button';

import * as DropdownList from '../../components/filter/list-dropdown';

import {
    configGridUser,
    listColumnUser
} from '../../components/datatable/data/user';
import DatatableComponent from '../../components/datatable/index2';
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";

import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';

import * as enums from '../../common/enum';

import * as api from '../../reducer/contains/api';
import { useSelector } from "react-redux";

import moment from "moment";
import { Messages } from 'primereact/messages';

const Index = () => {
    const msgs1: any = useRef(null);
    const account: any = useSelector((x: any) => x.auth.profile);

    const [show, setShow] = useState(false);
    const [username, setUsername] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [roleId, setRoleId] = useState<number>(0);
    const [password, setPassword] = useState<string>("");
    const [userId, setUserId] = useState<number>(0);

    const [timeMod, setTimeMod] = useState(0);
    const [header, setHeader] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTimeMod(moment().valueOf());
    }, [])

    const onAdd = (e: any) => {
        // e.preventDefault();
        setHeader("THÊM NHÂN VIÊN");
        setShow(true)
        setUsername("");
        setName("");
        setPassword("");
        setRoleId(0);
        setUserId(0)
    }

    const checkData = () => {
        if (roleId === 0) {
            msgs1.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn phân quyền !' })
            return false;
        }

        if (username === "") {
            msgs1.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tài khoản !' })
            return false;
        }

        if (name === "") {
            msgs1.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập họ và tên !' })
            return false;
        }

        if (password === "") {
            msgs1.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mật khẩu' })
            return false;
        }

        return true;
    }

    const addUser = async (e: any) => {
        e.preventDefault();

        const check = checkData();

        if (check) {
            let objAdd = {
                username,
                name,
                roleId,
                password
            }

            setLoading(true);
            // console.log(objAdd)

            await axiosWithHeaders("post", api.ADD_USER, objAdd)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        // console.log(response)
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            Swal.fire("Thông báo", "Thêm thành công !", "success");
                            onHideDialog();
                            setUsername("");
                            setName("");
                            setPassword("");
                            setRoleId(0);
                            setUserId(0)
                            setTimeMod(moment().valueOf())
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }
                })
                .catch((err) => {
                    Swal.fire("Lỗi", err.toString(), "error")
                }).finally(() => {
                    e.target.classList.remove("p-button-disabled");
                    setLoading(false);
                })
        }
    }

    const updateUser = async (e: any) => {
        e.preventDefault();

        const check = checkData();

        if (check) {
            let objUpdate = {
                username,
                name,
                roleId,
                password,
                userId
            }
            setLoading(true);
            // console.log(objUpdate);

            await axiosWithHeaders("post", api.UPDATE_USER, objUpdate)
                .then((response: any) => {
                    if (response && response.status === enums.STATUS_RESPONSE_OK) {
                        const { data } = response;
                        // console.log(response)
                        if (data && data.status === enums.STATUS_RESPONSE_OK) {
                            Swal.fire("Thông báo", "Cập nhật thành công !", "success");
                            onHideDialog();
                            setUsername("");
                            setName("");
                            setPassword("");
                            setRoleId(0);
                            setUserId(0)
                            setTimeMod(moment().valueOf())
                        } else {
                            Swal.fire("Lỗi", data.message, "error")
                        }
                    }
                })
                .catch((err) => {
                    Swal.fire("Lỗi", err.toString(), "error")
                }).finally(() => {
                    e.target.classList.remove("p-button-disabled");
                    setLoading(false);
                })
        }
    }

    const delUser = (e: any, userId: number) => {
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa nhân viên này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                // const data = enCryptData({userId: userId});
                // console.log({data: data}, userId)
                axiosWithHeaders('POST', api.DEL_USER, { userId: userId })
                    .then((response: any) => {
                        if (response && response.status === enums.STATUS_RESPONSE_OK) {
                            const { data } = response;
                            if (data && data.status === enums.STATUS_RESPONSE_OK) {
                                Swal.fire("Thông báo", "Xóa thành công !", "success");
                                setTimeMod(moment().valueOf())
                            } else {
                                Swal.fire("Lỗi", data.message, "error")
                            }
                        }
                    })
                    .catch((err) => {
                        Swal.fire("Lỗi", err.toString(), "error")
                    }).finally(() => {
                        e.target.classList.remove("p-button-disabled");
                        setLoading(false);
                    })
            }
        })
    }

    const onHideDialog = () => {
        setShow(false);
    }

    const renderDialog = (
        <Dialog header={header} visible={show} onHide={onHideDialog} style={{ width: 600 }} focusOnShow={false}>
            <div className="row">
                <div className="col-md-12">
                    <Messages ref={msgs1} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <b>Phân quyền(*)</b>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <DropdownList.ListRole value={roleId} onChange={(e: any) => setRoleId(e.value)} roleId={account ? account.roleId : 0} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <b>Tài khoản(*)</b>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <input placeholder="Nhập tài khoản" className="p-component p-inputtext" value={username} style={{ width: `100%` }} onChange={(e: any) => setUsername(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <b>Họ và tên(*)</b>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <input placeholder="Nhập tên nhân viên" className="p-component p-inputtext" value={name} style={{ width: `100%` }} onChange={(e: any) => setName(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <span>Mật khẩu</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" style={{ marginTop: 10 }}>
                    <input
                        placeholder="*****"
                        type="password" className="p-component p-inputtext" value={password} style={{ width: `100%` }} onChange={(e: any) => setPassword(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center" style={{ marginTop: 40 }}>
                    {userId === 0 ? (
                        <Button className="p-button p-component p-button-danger" label="Xác nhận" onClick={addUser} loading={loading} />
                    ) : (
                        <Button className="p-button p-component p-button-danger" label="Xác nhận" onClick={updateUser} loading={loading} />
                    )}
                </div>
            </div>
        </Dialog>
    )

    const editRow = (e: any, row: any) => {
        e.preventDefault();
        setHeader("CẬP NHẬT NHÂN VIÊN");
        setUsername(row.username);
        setName(row.name);
        setPassword(row.password ? row.password : "");
        setRoleId(row.roleId);
        setUserId(row.userId);
        setShow(true);
    }

    const cellTemplate = (row: any, cell: any) => {
        // console.log(row)
        switch (cell.field) {
            case 'stt':
                return 1 + cell.rowIndex;
            case 'action':
                return (
                    <>
                        <Button
                            icon="pi pi-pencil"
                            disabled={(account && account.roleId === 1) ? false : true}
                            className="p-button-sm" onClick={(e: any) => editRow(e, row)}
                            style={{ marginRight: 10 }}
                        />
                        {(account !== undefined && account !== null && account.roleId === 1) && (
                            <Button icon="pi pi-trash" className="p-button-sm p-button-danger" onClick={(e: any) => delUser(e, row.userId)} />
                        )}
                    </>
                )
            default:
                return row[cell.field];
        }
    }

    return (
        <Fragment>
            <DatatableComponent
                header="Danh sách nhân viên"
                columns={listColumnUser}
                filters={listFilterUser}
                config={configGridUser}
                onAdd={onAdd}
                timeModify={timeMod}
                cellTemplate={cellTemplate}
            />
            {renderDialog}
        </Fragment>
    );
}

export default Index;