import React, { Fragment, useState, useEffect } from "react";
import Chart from "react-apexcharts";

const LineChart = (props) => {
  //console.log(props)  
 return  <>
    <Chart options={props.options}  series={props.series} type="radialBar" height={props.height} />
    </>
}

export default LineChart;