
export const listFilter = [
   
    // {
    //     field: 'supermarketId',
    //     title: 'Hệ thống siêu thị',
    //     type: 'dropdown',
    //     value: '1',
    // //    component: 'market-group',
    //   //  sort: 0
    // },
    // {
    //     field: 'supermarketLocationId',
    //     title: 'Siêu thị',
    //     type: 'dropdown',
    //     value: '',
    //     component: 'market-location',
    //     sort: 1
    // },
    {
        field: 'status',
        title: 'Hình thức gửi',
        type: 'dropdown',
        value: '0',
        component: 'type-status',
        sort: 2
    },
    {
        field: 'search',
        title: 'Từ khóa',
        type: 'string',
        value: '',
        component: '',
        sort: 3
    },
     
]