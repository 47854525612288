import React, { useState } from "react";
import { connect } from 'react-redux';
import * as enums from "../../common/enum";
import { axiosWithHeaders } from "../../reducer/contains/axiosWrapper";
import {
    RESET_LUCKY,
    FINISH_LUCKY
} from "../../reducer/contains/api";
import * as actions from '../../reducer/actions/index';
import {
    withRouter
} from "react-router-dom";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";
import listDropdown from "./listDropdown";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
const ResetLucky = (props: any) => {
    const{profile} = props
    React.useEffect(() => {
        if( ![1,2,48,52].includes(profile.id)){
            props.history.push("/");
        }
     }, []);

    const [product, setProduct] = useState(listDropdown[3])
    const [turn, setTurn] = useState(3);

    const onResetLucky = (Turn: number, ProductId: number) => {
        Swal.fire({
            title: 'Thông báo',
            text: `Bạn có muốn xác nhận reset danh sách quay thưởng đợt ${Turn} không?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders("get", `${RESET_LUCKY}${Turn}&ProductId=${ProductId}`, undefined)
                .then((response: any) => {
                    if(response.data && response.status === enums.STATUS_RESPONSE_OK){
                        const {
                            status,
                            message,
                        } = response.data;
                        if(status === enums.STATUS_RESPONSE_OK){
                            alert(`reset thành công`);
                        }
                        else{
                            alert(message);
                        }
                    }
                })
                .catch((error) => {
                    alert(error.toString());
                });
            }
        })

    }

    return (
        <div style={{
            display: 'flex',
            marginBottom: 100
        }}>
            <Dropdown>
                <DropdownToggle
                    id='dropdown-turn'
                    variant="warning"
                    style={{
                        width: '200px',
                    }}
                >
                    Đợt {turn}
                </DropdownToggle>
                <Dropdown.Menu align='end'>
                    {[3].map((item) => (
                        <Dropdown.Item key={item} onClick={() => setTurn(item)}>Đợt {item}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <DropdownToggle
                    id='dropdown-productId'
                    variant="danger"
                    style={{
                        width: '200px',
                        margin: '0 10px',
                    }}
                >
                    {product.productName}
                </DropdownToggle>
                <Dropdown.Menu>
                    {listDropdown.map((item) => (
                        <Dropdown.Item key={item.productId} onClick={() => setProduct(item)}>{item.productName}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <button className="learn-more" onClick={()=>onResetLucky(turn, product.productId)}>Reset quay số ({product.productName} - Đợt {turn}) </button>
        </div>

    );
}

const mapStateToProps = state => {
    // console.log(state.datadefault.listCost);
    return {
        profile: state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetLucky));