import jwt from 'jsonwebtoken'
import {
        REACT_APP_SECRET_KEY_CLIENT
    ,REACT_APP_JWT
    ,REACT_APP_SECRET_KEY,
    REACT_APP_JWT_REFESH,
    REACT_APP_JWT_PROFILE
    } from '../common/config';
var CryptoJS = require("crypto-js");

export function enCryptData(decipherText:any) {

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(decipherText), REACT_APP_SECRET_KEY).toString();
  
    return ciphertext;
}


export function deCryptData(cipherText:string) {
    var bytes  = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}

export function enCryptDataClient(decipherText:any) {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(decipherText), REACT_APP_SECRET_KEY_CLIENT).toString();
    return ciphertext;
}




export function deCryptDataClient(cipherText:string) {
    try {
        var bytes  = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY_CLIENT);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
        
    } catch (error) {
        return null
    }
    
}

export function GetProfile(){
    let token = deCryptProfile()
 
    if(token){
     
        // let decoded = jwt.decode(token);
        // let obj = deCryptData(decoded.data);
            return token;
    }
    return null;

}


export function GetProfileString(token){
    // console.log(token)
    // let decoded = jwt.decode(token);
  //  let obj = deCryptData(token);
    return token;
}

export function GetJWT(){
    let jwt = REACT_APP_JWT as string;
    let browserToken = localStorage.getItem(jwt);
    return browserToken;
}

export function deCryptToken(){
    let browserToken = localStorage.getItem(REACT_APP_JWT as string) as string;
    if(browserToken){

        return deCryptDataClient(browserToken)
    }
    return null
    
}

export function deCryptProfile(){
    let browserToken = localStorage.getItem(REACT_APP_JWT_PROFILE as string) as string;
    if(browserToken){

        return deCryptDataClient(browserToken)
    }
    return null
    
}

export function deCryptRefreshToken(){
   
    let browserToken = localStorage.getItem(REACT_APP_JWT_REFESH as string) as string;
  
    if(browserToken){

        return deCryptDataClient(browserToken)
    }
    return null
    
}