const listDropdown = [
  {
    productId: 5,
    productName: "Giải đặc biệt",
    prize: "XE MÁY HONDA LEAD 125cc 2022",
  },
  {
    productId: 4,
    productName: "Giải nhất",
    prize: "IPAD AIR 5 M1 5G",
  },
  {
    productId: 3,
    productName: "Giải nhì",
    prize: "HỘP QUÀ BÁNH KẸO TẾT",
  },
  {
    productId: 2,
    productName: "Giải ba",
    prize: "NÓN BẢO HIỂM",
  },
];

export default listDropdown;
