import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  box-shadow: 1px 5px 25px rgb(0 0 0 / 10%);
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    z-index: 9999;
  }

  ul.first-child{
    margin-top: 40px;
    margin-bottom: unset;
    padding-inline-start: 0px;
    list-style-type: none;
  }  

  a {
    background-color: transparent;
    font-size: 16px;
    padding: 10px 15px;
    text-transform: uppercase;
    margin-top: unset;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  ul.second-child > li a{
    font-weight: 300;
  }
`;