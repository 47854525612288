
import React, { useState, useRef, useEffect, useMemo, Fragment, useCallback } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './reducer/reducers/storereducer';
import { withRouter } from 'react-router-dom';
import RootComponent from './RootComponent'
import OneSignal from 'react-onesignal';
// import LoadingView from './components/loading/loading';

function App(props: any) {
    // useEffect(() => {
    //   // OneSignal.init({
    //   //   appId: "e5aca5df-7592-45c0-b177-99eea2d69c55"
    //   // });
    //   runOneSignal()
    
    // }, []);

    // async function runOneSignal() {
    //   await OneSignal.init({ appId: 'e5aca5df-7592-45c0-b177-99eea2d69c55', allowLocalhostAsSecureOrigin: true

    
    // });
    //   OneSignal.showSlidedownPrompt();

    //   OneSignal.on('subscriptionChange', function(isSubscribed) {
    //     console.log("The user's subscription state is now:", isSubscribed);
    //   });
    //   OneSignal.isPushNotificationsEnabled(function(isEnabled) {
    //     if (isEnabled)
    //       console.log("Push notifications are already enabled!");
    //     else
    //       console.log("Push notifications are not enabled yet.");
    //       OneSignal.showSlidedownPrompt();
    //   });
    // }
    // useEffect(() => {
    //   // OneSignal.init({
    //   //   appId: "e5aca5df-7592-45c0-b177-99eea2d69c55",
    //   //   subdomainName :"http://localhost:3018"
    //   // });
    //   // await OneSignal.init({ appId: "e5aca5df-7592-45c0-b177-99eea2d69c55", allowLocalhostAsSecureOrigin: true});
    //   // OneSignal.showSlidedownPrompt();
    //   runOneSignal()
    // }, []);

  // useEffect(() => {
  //   OneSignal.init({  appId: "b40b7cc7-13dc-4662-8b48-efa668f9b72a", allowLocalhostAsSecureOrigin: true});
  //   OneSignal.showSlidedownPrompt();
  // })
  //   useEffect(() => {
  //     console.log(11111)
  //     OneSignal.init({
  //       appId: "e5aca5df-7592-45c0-b177-99eea2d69c55",
  //       notifyButton: {
  //           enable: true,
  //       },
  //       autoResubscribe:true,
  //       persistNotification:true,

  //       allowLocalhostAsSecureOrigin: true,
  //   });
  //   OneSignal.showSlidedownPrompt();
  //   console.log( OneSignal.getUserId())
  //   console.log(OneSignal.isPushNotificationsEnabled())

  //   OneSignal.on('subscriptionChange', function(isSubscribed) {
  //     console.log("The user's subscription state is now:", isSubscribed);
  //   });
  //   OneSignal.on('notificationPermissionChange', function(isSubscribed) {
  //     console.log("The user's notificationPermissionChange state is now:", isSubscribed);
  //   });
  //   OneSignal.on('notificationDismiss', function(isSubscribed) {
  //     console.log("The user's notificationDismiss state is now:", isSubscribed);
  //   });
    
  // }, []);
  
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  );
}

export default withRouter(App);
