import {GetURL,GetURLReport} from '../../common/config';


export const LOGIN = `${GetURL()}/api/auth/authenticate`;

export const REPORT_ALL = `${GetURLReport()}/api/report/reportdetail`;
export const REPORT_CODE = `${GetURLReport()}/api/report/reportcode`;
export const REPORT_TURN = `${GetURLReport()}/api/report/reportturn`;
export const CHECK_LINK = `${GetURL()}/api/social/checklink`;

export const CHECK_LINK_SUPER = `${GetURL()}/api/social/checklinksuper`;

export const LUCKY_BIGC = `${GetURL()}/api/social/lucky`;
export const LUCKY_RICOLA = `${GetURL()}/api/product/luckydraw`;
export const LUCKY_ANNAM = `${GetURL()}/api/luckydraw/lucky`;

export const LUCKY_SPECIAL = `${GetURL()}/api/social/luckysuper`;

export const UPDATE_CUSTOMER = `${GetURL()}/api/social/updatecustomer`;

export const LIST__Bill_SOCIAL = `${GetURL()}/api/social/listbill`;

export const SOCIAL_DETAIL = `${GetURL()}/api/social/detail?id=`;

export const SOCIAL_DETAIL_BILL = `${GetURL()}/api/social/detailaccept?id=`;

export const ACCEPT_SOCIAL = `${GetURL()}/api/social/acceptbill`;

export const CANCEL_SOCIAL = `${GetURL()}/api/social/cancelbill`;

export const LIST_SOCIAL_ACCEPT_REPORT = `${GetURL()}/api/social/listsocialacceptupdate`;

export const LIST_PRODUCT = `${GetURL()}/api/productbuy/listproduct`;
export const LIST_PRODUCT_RESULTAI = `${GetURL()}/api/productbuy/listproductresultai`;

export const LIST_SUPERMARKET = `${GetURL()}/api/social/listsupermarket`;
export const LIST_SUPERMARKET_FULL = `${GetURL()}/api/social/listsupermarketfull`;

export const RESULT_GIFT = `${GetURL()}/api/social/resultgift`;

export const LIST_GIFT = `${GetURL()}/api/gift/list`;

export const LIST_CUSTOMER = `${GetURL()}/api/social/listcustomer`;
export const LIST_CUSTOMER_PRODUCT = `${GetURL()}/api/luckydraw/list`;
export const RESET_LUCKY = `${GetURL()}/api/luckydraw/clear?Turn=`;
export const FINISH_LUCKY = `${GetURL()}/api/luckydraw/set?Turn=`;

export const LIST_REPORT = `${GetURL()}/api/report/reportall`;

export const LIST_MESSAGE = `${GetURL()}/api/social/listmesssage`;

export const LIST_REPORT_DETAIL = `${GetURL()}/api/report/reportdetail`;

export const LIST_CUSTOMER_GIFT = `${GetURL()}/api/social/gifts`;

export const CREATE_LINK = `${GetURL()}/api/social/createlink`;

export const DELL_BILL = `${GetURL()}/api/social/deletebill`;

export const UPDATE_STATUS_CUSTOMER = `${GetURL()}/api/social/updatestatuscustomer`;

export const INFO_SOCIALID = `${GetURL()}/api/social/infosocial`;

//Bổ sung event
export const UPDATE_CUSTOMER_BIGC = `${GetURL()}/api/social/updatecustomerbigc`;

//test quay quà
export const LUCKY_TEST = `${GetURL()}/api/social/luckytest`;

// API DS SUPERMARKET LOCATION
export const SUPERMARKET_LOCATION = `${GetURL()}/api/productbuy/supermarketlocation`;

export const LOAD_GIFT_DATE = `${GetURL()}/api/gift/loaddate`;

export const UPDATE_TOTALGIFT = `${GetURL()}/api/gift/update`;


export const REFRESHTOKEN = `${GetURL()}/api/refresh-token`;

export const UPLOAD_SINGlE_FILE = `${GetURL()}/api/single`;

//warehouse
export const WAREHOUSE = `${GetURL()}/api/listwarehouse`;

//customer
export const CUSTOMER = `${GetURL()}/api/listcustomer`;

//User
export const LIST_USER = `${GetURL()}/api/user/list`;
export const ADD_USER = `${GetURL()}/api/auth/create`;
export const DETAIL_USER = `${GetURL()}/api/user/detail`;
export const UPDATE_USER = `${GetURL()}/api/auth/update`;
export const DEL_USER = `${GetURL()}/api/user/del`;
export const RESET_PASSWORD = `${GetURL()}/api/resetpassword`;
export const CHANGE_PASSWORD = `${GetURL()}/api/changepassword`;

export const LIST_PROVINCE = `${GetURL()}/api/listprovince`;

export const LIST_USER_ROLE = `${GetURL()}/api/listuserrole`;
export const LIST_USER_BY_STOREID = `${GetURL()}/api/listuserbystoreId`;
export const DETAIL_SOCIALPRODUCTBUY_ID = `${GetURL()}/api/social/detailprdbill?id=`;

//fileupload
export const FILE_UPLOAD = `${GetURL()}/api/single`;

export const LIST_STORE = `${GetURL()}/api/supermarket/list`;
export const UPDATE_DATE_STORE = `${GetURL()}/api/supermarket/update-date`;
export const LIST_STORE_LOCATION = `${GetURL()}/api/supermarket/list-location`;
export const CREATE_STORE_LOCATION = `${GetURL()}/api/supermarket/create-supermarketlocation`;
export const UPDATE_STORE_LOCATION = `${GetURL()}/api/supermarket/update-supermarketlocation`;
export const LIST_PRODUCT_MARKET = `${GetURL()}/api/product/listfull?supermarketId=0`;
export const LIST_FOLLOWER = `${GetURL()}/api/follower/list`;
export const BLACK_LIST = `${GetURL()}/api/blacklist/list`;
export const BLACK_LIST_ADD = `${GetURL()}/api/blacklist/create`;
export const BLACK_LIST_UPDATE = `${GetURL()}/api/blacklist/create`;
export const BLACK_LIST_DEL = `${GetURL()}/api/blacklist/del`;
export const LIST_BILL_ACCEPT = `${GetURL()}/api/social/listbillaccept`;

export const ASSIGN_GIFT = `${GetURL()}/api/totalgift/list`;
export const ADD_ASSIGN_GIFT = `${GetURL()}/api/totalgift/create`;
export const UPDATE_ASSIGN_GIFT = `${GetURL()}/api/totalgift/update`;
export const DETAIL_ASSIGN_GIFT = `${GetURL()}/api/totalgift/loaddate`;
export const DEL_ASSIGN_GIFT = `${GetURL()}/api/totalgift/del`;
export const IMPORT_UPDATE_DATE = `${GetURL()}/api/report/import-update-date`;
export const IMPORT_UPDATE_PRODUCT = `${GetURL()}/api/report/import-update-product`;
export const CHECK_SOCIAL = `${GetURL()}/api/product/checksocial`;
export const SET_PRODUCT = `${GetURL()}/api/product/setproduct`;
export const CUSTOMER_FILTER = `${GetURL()}/api/customer/listfilter`;
export const LIST_PRODUCT_MESSAGE = `${GetURL()}/api/product/list`;
export const UPDATE_PRIORITY = `${GetURL()}/api/product/updatedefault`;
export const UPDATE_PRODUCT_MESSAGE = `${GetURL()}/api/product/updatemessage`;
export const SET_PRODUCT_LIST = `${GetURL()}/api/product/setproductlist`;

export const LIST_BILL_SUPERMARKET = `${GetURL()}/api/setup/listbill`;
export const UPDATE_BILL_SUPERMARKET = `${GetURL()}/api/setup/addbill`;
export const SET_PRODUCT_LIST_TAI = `${GetURL()}/api/setup/setproductlist`;
export const CHECK_BILL_CODE = `${GetURL()}/api/social/checkbill`;


// BLackList Zalo
export const BLACK_LIST_ZALO = `${GetURL()}/api/blacklistzalo/list`;
export const BLACK_LIST_ZALO_ADD = `${GetURL()}/api/blacklistzalo/create`;
export const BLACK_LIST_ZALO_UPDATE = `${GetURL()}/api/blacklistzalo/create`;
export const BLACK_LIST_ZALO_DEL = `${GetURL()}/api/blacklistzalo/del`;

export const DASHBOARD = `${GetURL()}/api/dashboard/dashboard`;
export const DASHBOARD_ALL = `${GetURL()}/api/dashboard/dashboardall`;
export const DASHBOARD_BARCHART = `${GetURL()}/api/dashboard/dashboard-barchart`;
