import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    *, *::after, *::before {
        box-sizing: border-box;
    }

    body {
        align-items: center;
        // background: #0D0C1D;
        // color: #EFFFFA;
        background: ${({ theme }) => theme.primaryLight};
        color: ${({ theme }) => theme.primaryDark};
        display: flex;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        height: 100vh;
        justify-content: center;
        text-rendering: optimizeLegibility;
    }

    body .p-datepicker table th{
        text-align: center;
    }

    #root{
        width: 100%;
        height: 100%;
    }    

    body .swal2-backdrop-show{
        z-index: 10000
    }

    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown){
        height: 100%!important;
    }

    .p-autocomplete-input{
        width: 100%;
    }

    .p-datepicker-trigger{
        background: transparent;
        color: #ced4da;
        border-color: #ced4da;
    }

    .p-inputtext:enabled:focus, .p-datepicker-trigger:focus{
        outline: none;
        box-shadow: none;
    }

    .p-calendar-w-btn .p-inputtext{
        border-right: unset
    }

    .p-calendar-w-btn .p-inputtext:hover{
        border-color: #ced4da;
    }

    .p-calendar-w-btn .p-datepicker-trigger{
        border-left: unset
    }

    .p-calendar-w-btn .p-datepicker-trigger:hover{
        border-color: transparent;
        background: transparent
    }

    .p-dialog .p-dialog-header{
        padding: 0.75rem 1.5rem;
    }

    .p-datatable .p-datatable-thead > tr > th.text-center > .p-column-header-content{
        justify-content: center
    }


    .p-button-disabled{
        pointer-events: none;
        filter: grayscale(1);
    }

    .p-button-label{
        pointer-events: none;
    }

    .p-message-summary{
        margin-right: 5px;
        font-weight: bold;
    }
  `