import React, { Fragment, useEffect, useState } from 'react';
import {
    listFilter
} from '../../components/filter/data/follower';

import {
    configGrid,
} from '../../components/dataview/data/follower';

import DataView from '../../components/dataview/index2';
import Lightbox from '../../components/Lightbox-component/index';
import styled from 'styled-components';
import moment from 'moment';

const ItemImage = styled.div`
    width: 80px;
    height: 80px;
`
const ItemImageContent = styled.div`
    width: calc(100% - 80px);
    padding-left: 10px;
`

const Index = () => {
    const [timeMod, setTimeMod] = useState(0);

    useEffect(() => {
        setTimeMod(moment().valueOf());
    }, [])


    const renderListItem = (item: any) => {
        // console.log(item);

        const image = [{
            src: item.avatar    ,
            alt: item.nameZalo,
            title: item.nameZalo,
            description: ""
        }]

        return (
            <div className='col-12'>
                <div className="d-flex" style={{ padding: `10px 15px`, position: 'relative', overflowX: `hidden` }}>
                    <ItemImage>
                        <Lightbox
                            images={image}
                            renderImageFunc={(idx: number, image: any, toggleLightbox: any) => {
                                return (
                                    <img
                                        key={idx}
                                        src={image.src}
                                        alt="Hình mô tả"
                                        style={{ width: 80, height: 80, objectFit: "contain", cursor: "pointer", backgroundColor: `#f4f4f4`, borderRadius: 10 }}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                )
                            }} />
                    </ItemImage>
                    <ItemImageContent>
                        <div className='container-fluid'>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    <b>{`ID: ` + item.followerId}</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-12' style={{ marginTop: 10 }}>
                                    Tên hiển thị: <b>{item.nameZalo}</b>
                                </div>
                            </div>
                        </div>

                        {/* {JSON.stringify(item)} */}
                    </ItemImageContent>
                </div>
            </div>
        );
    }

    const itemTemplate = (item: any, layout: string) => {
        if (item) {
            if (layout === "list") {
                return renderListItem(item);
            }
        }
        return;
    }


    return (
        <Fragment>
            <DataView
                header='Danh sách người theo dõi'
                filters={listFilter}
                config={configGrid}
                itemTemplate={itemTemplate}
                timeUpdate={timeMod}
            />
        </Fragment>
    );
}

export default Index;