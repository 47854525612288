import * as api from "../../../reducer/contains/api";

export const configGrid = {
    useDateRange: false,
    // allowAdd: false,
    // titleAdd: "Thêm hệ thống",
    api: api.LIST__Bill_SOCIAL,
    defaultFilter: {
      search: "",
    },
  };
  