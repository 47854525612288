
export const listFilterUser = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        value: '',
        component: '',
        sort: 0
    }    
]