import styled from 'styled-components';

export const StyledSideBar = styled.aside`
  position: fixed;
  background-color: ${({ theme }) => theme.primaryMenuColor};
  transition: transform 0.3s ease-in-out;
  box-shadow: 1px 5px 25px rgb(0 0 0 / 10%);
  // height: calc(100% - 30px);
  color: #fff;
  border-radius: 15px;
  //width: 300px;
  width: ${({ open }) => open ? '300px' : '30px'};
  position: relative;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    display: none
  }

  ul.first-child{
    margin-top: 30px;
    margin-bottom: unset;
    padding-inline-start: 0px;
    list-style-type: none;
  }

  ul.first-child li{
    padding: 0 10px;
  }

  a {
    background-color: transparent;
    font-size: 1rem;
    padding: 10px 15px;
    text-transform: uppercase;
    margin-top: unset;
    font-weight: 500;
    // font-family: 'Roboto', sans-serif !important;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.primaryLight};
    text-decoration: none;
    transition: color 0.3s linear;
    text-transform:capitalize;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      // font-size: 12px;
      text-align: center;
      
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  a.active{
    color: ${({ theme }) => theme.primaryActiveColor};
  }

  ul.second-child > li a{
    font-weight: 400;
  }
`