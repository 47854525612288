import React, { Fragment, useState, useEffect } from "react";
import {
    listFilter
} from '../../components/filter/data/customer';

import { Button } from 'primereact/button';
import {
    configGrid,
    listColumn
} from '../../components/datatable/data/customer';
import DatatableComponent from '../../components/datatable/index2';
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";

import {
    axiosWithHeaders
} from '../../reducer/contains/axiosWrapper';

import * as enums from '../../common/enum';
import * as actions from '../../reducer/actions';
import * as api from '../../reducer/contains/api';
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from 'primereact/avatar';
import moment from "moment";
import Lightbox from "react-awesome-lightbox";

const Index = () => {
    const account: any = useSelector((x: any) => x.auth.profile);
    const dispatch = useDispatch();

    const [timeMod, setTimeMod] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [infoZalo, setInfoZalo] = useState<any>(null);
    const [showDialogBill, setShowDialogBill] = useState(false);
    const [rotate, setRotate] = useState<number>(0);
    const [heightDevice, setHeightDevice] = useState<number>(0);
    const [naturalWidth, setNaturalWidth] = useState<number>(0);
    const [naturalHeight, setNaturalHeight] = useState<number>(0);
    const [widthDevice, setWidthDevice] = useState<number>(0);
    const [link, setLink] = useState<any>(null);
    useEffect(() => {
        setTimeMod(moment().valueOf())
        setHeightDevice(window.innerHeight)
        setWidthDevice(window.innerWidth)
    }, [])

    const onHide = () => {
        setShowDialog(false)
        setInfoZalo(null)
    }

    const onHideBill = () => {
        setShowDialogBill(false)
     
    }

    const updateStatusBill = (e: any, row: any) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn cập nhật khách hàng này đã trao quà rồi trong  danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {              
                axiosWithHeaders('GET', `${api.UPDATE_STATUS_CUSTOMER}?id=${row.id}`, null)
                    .then((response: any) => {
                        if (response !== undefined && response !== null && Object.keys(response).length > 0) {                            
                            let { status, data } = response;
                            if (status === enums.STATUS_RESPONSE_OK) {
                                Swal.fire("Thông báo", "Cập nhật thành công !", "success");
                                setTimeMod(moment().valueOf());
                            } else {
                                Swal.fire("Thông báo", data.message, "error");
                            }
                        }                      
                    }).catch(err => {
                        dispatch(actions.error_success("error", "Thông báo", "Hệ thống đang xảy ra lỗi " + err.toString()));
                    });
            }
        })
    }


    const viewInfo = (e: any, row: any) => {
      //  console.log(row.socialId)
        axiosWithHeaders('GET', `${api.INFO_SOCIALID}?id=${row.socialId}`, null)
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                    // let response = data;
                    //  console.log(response);
                    let { status, data } = response;
                    if (status === enums.STATUS_RESPONSE_OK) {
                        if (data.error === 0) {
                            setInfoZalo(data.data);
                            setShowDialog(true);
                        }
                        else {
                            dispatch(actions.error_success("error", "Thông báo", data.message));
                        }
                    } else {
                        dispatch(actions.error_success("error", "Thông báo", "Hệ thống đang xảy ra lỗi " + status));
                    }
                }
            }).catch(err => {
                dispatch(actions.error_success("error", "Thông báo", "Hệ thống đang xảy ra lỗi " + err.toString()));
            });
    }

    const cellTemplate = (row: any, cell: any) => {
        switch (cell.field) {
            case 'stt':
                return 1 + cell.rowIndex;
            case 'action':
                return (
                    <>
                      
                        {/* {(account !== undefined && account !== null && (account.roleId === 1 || account.roleId === 2) && ( ![5,8].includes(row["status"])) && (
                            <>
                                <Button 
                                    icon="pi pi-pencil" 
                                    tooltip="Cập nhật trạng thái đã trao quà"
                                    className="p-button-rounded p-button-success p-button-text p-button-sm" 
                                    tooltipOptions={{ position: 'bottom' }} 
                                    onClick={(e) => updateStatusBill(e, row)} 
                                />
                            </>
                        ))} */}
                           
                    </>
                )
            default:
                return row[cell.field];
        }
    }


    const viewBill = (e: any, row: any) => {
            setLink(row.dateCreate)
            setShowDialogBill(true);
                           
      }

    const dialogInfoZalo = (
        <Dialog header="Thông tin Zalo" visible={showDialog} style={{ width: '60%' }} onHide={() => onHide()}>
            {infoZalo ? (
                <>
                    <div className='d-flex justify-content-center'>
                        <Avatar style={{ width: 120, height: 120 }} image={infoZalo.avatar} />
                    </div>
                    <div className='d-flex'>
                        <div style={{ paddingTop: 10, width: 120 }}>Tên người dùng</div><div style={{ paddingTop: 10 }}><b>&nbsp;&nbsp;{infoZalo.display_name}</b></div>
                    </div>
                    <div className='d-flex'>
                        <div style={{ paddingTop: 10, width: 120 }}>Giới tính</div><div style={{ paddingTop: 10 }}><b>&nbsp;&nbsp;{(infoZalo.user_gender === "1") ? `Nam` : `Nữ`}</b></div>
                    </div>
                    <div className='d-flex'>
                        <div style={{ paddingTop: 10, width: 120 }}>Social ID</div><div style={{ paddingTop: 10 }}><b>&nbsp;&nbsp;{infoZalo.user_id}</b></div>
                    </div>
                </>
            ) : (
                <div className='p-grid' style={{ paddingTop: 10 }}>
                    <span style={{ fontSize: 14 }}>Thông tin người sử dụng Zalo không tồn tại !</span>
                </div>
            )}

        </Dialog>
    )

    const dialogImageBill = (
     
        <Dialog header="Hình ảnh bill" visible={showDialogBill} style={{ width: '60%', height:'100%' }} onHide={() => onHideBill()}>
            <div className='d-flex align-items-center' style={{ width: "100%", position: `relative` }}>
                <Lightbox image={link} title="BILL" />
            </div> 
        </Dialog>
   
    )

    return (
        <Fragment>
            <DatatableComponent
                header="Khách hàng trúng thưởng"
                columns={listColumn}
                filters={listFilter}
                config={configGrid}
                timeModify={timeMod}
                cellTemplate={cellTemplate}
            />
            {dialogInfoZalo}
            {dialogImageBill}
        </Fragment>
    );
}

export default Index;