import React, { useState, useEffect } from "react";
import * as api from '../../reducer/contains/api';
import {
    axiosWithHeaders
    // , axiosWithHeadersBlob 
} from '../../reducer/contains/axiosWrapper';
import * as Status from '../../common/enum';
// import { useDispatch } from "react-redux";
// import * as actions from '../../reducer/actions';
import { AutoComplete } from 'primereact/autocomplete';
import * as _ from 'lodash';
import Swal from "sweetalert2";

export const MarketLocation = ({ value, onChange, supermarketId }) => {
    // const dispatch = useDispatch();
    const [listSupermarketLocation, setListSupermarketLocation] = useState([]);
    const [listSupermarketLocationFilter, setListSupermarketLocationFilter] = useState([]);
    // const [currentMarketId, setCurrentMarketId] = useState<number>(0);

    // useEffect(() => {
    //     if (supermarketId && parseInt(supermarketId) !== currentMarketId) {
    //         let listAllMarket: any = _.cloneDeep(listSupermarketLocation);
    //         listAllMarket = _.filter((location: any) => {
    //             return location.supermarketId === parseInt(supermarketId);
    //         })
    //         console.log(listAllMarket)
    //         setListSupermarketLocationFilter(listAllMarket)
    //         setCurrentMarketId(supermarketId);
    //     }

    // }, [supermarketId, currentMarketId, listSupermarketLocation])

    const searchLocation = (e: any) => {
        let filteredLocation: any = [];
        // console.log(supermarketId);
        setTimeout(() => {
            if (e.query.trim().length > 0) {
                filteredLocation = _.cloneDeep(listSupermarketLocationFilter);
                filteredLocation = listSupermarketLocation.filter((location: any) => {
                    // console.log(location);
                    return (location.supermarketLocation.toLowerCase().includes(e.query.toLowerCase()) || location.nameUnicode.toLowerCase().includes(e.query.toLowerCase()))  && (parseInt(supermarketId) == 0 || location.supermarketId === parseInt(supermarketId));
                });
            }
            // console.log(filteredLocation);
            setListSupermarketLocationFilter(filteredLocation);
        }, 250);
    }



    useEffect(() => {
        const LoadListSuperMarketLocation = async () => {
            await axiosWithHeaders('GET', `${api.SUPERMARKET_LOCATION}`, null)
                .then((data: any) => {
                    if (data !== undefined && data !== null) {
                        let response = data.data;
                        let { status, message } = response;
                        if (status === Status.STATUS_RESPONSE_OK) {
                            let result = response.result;
                            // console.log(result);
                            setListSupermarketLocation(result);
                        }
                        else {
                            Swal.fire("Lỗi", message, "error")
                        }
                    }

                }).catch(err => {
                    // dispatch(actions.error_success('error', 'Lỗi', "Có lỗi xảy ra !" + err));
                    Swal.fire("Lỗi", err.toString(), "error")
                });

        }

        LoadListSuperMarketLocation();
    }, [])



    return (
        <>
            <AutoComplete
                value={value}
                suggestions={listSupermarketLocationFilter}
                completeMethod={searchLocation}
                field="supermarketLocation"
                placeholder="Tìm kiếm siêu thị..."
                onChange={(e: any) => onChange(e)}
                style={{ width: "100%" }}
            // dropdown
            />
        </>
    );
}

MarketLocation.propTypes = {

}
// export default GroupMarket;