import "ka-table/style.css";
import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Table } from "ka-table";
import * as enums from "../../common/enum";
import { axiosWithHeaders } from "../../reducer/contains/axiosWrapper";
import { LIST_CUSTOMER_PRODUCT, LUCKY_ANNAM } from "../../reducer/contains/api";
import { cloneDeep } from "lodash";
import * as actions from "../../reducer/actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import listDropdown from "./listDropdown";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const RenderDelayWrapper = ({ delay, children }: { delay: number; children: React.ReactNode }) => {
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    const handle = async () => {
      await sleep(delay);
      setIsRender(true);
    };
    handle();
  }, []);

  if (!isRender) return null;

  return <>{children}</>;
};

const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
const makeid = (length) => {
  let result = "";
  const charactersLength = CHARACTERS.length;
  let counter = 0;
  while (counter < length) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const RandomText = ({
  length,
  result,
  delay = 10000,
}: {
  length: number;
  result: string;
  delay?: number;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        animation: `slide-up ${delay}ms ease-out forwards`,
        width: "100%",
        textAlign: "center",
        fontFamily: "UTM-Swiss-CondensedBold",
      }}
    >
      {[...Array(delay * 0.01)].map((_, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          key={index + makeid(length)}
        >
          {makeid(length)}
        </div>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          // color: "#000",
        }}
      >
        {result}
        {/* <span
          style={{
            position: "absolute",
            top: '100%'
          }}
        >
          {makeid(length)}
        </span> */}
      </div>
    </div>
  );
};

const column = [
  { key: "col1", title: "STT", field: "no", width: `25%` },
  { key: "col2", title: "Mã dự thưởng", field: "code", width: `50%` },
  { key: "col3", title: "Số điện thoại", field: "phone", width: `25%` },
];

const drawDay = ["22 / 12 / 2023", "22 / 01 / 2024", "01 / 03 / 2024"];

function Lucky(props: any) {
  const searchParams = new URLSearchParams(props.location.search);
  const Turn = searchParams.get("Turn");
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState(listDropdown[3]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [listRandom, setListRandom] = useState<{ code: string; phone: string }[]>([
    // { code: "0BWC53G", phone: "123123123123" },
    // { code: "1234567", phone: "123123123123" },
    // { code: "1234567", phone: "123123123123" },
    // { code: "1234567", phone: "123123123123" },
    // { code: "1234567", phone: "123123123123" },
    // { code: "1234567", phone: "123123123123" },
  ]);
  const { profile } = props;
  React.useEffect(() => {
    if (![1, 2, 48, 52].includes(profile.id)) {
      props.history.push("/");
    }
  }, []);

  useEffect(() => {
    const audio = document.getElementById("lucky-audio") as HTMLAudioElement;
    audio?.load();
    audio?.play();

    return () => {
      audio?.pause();
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      setDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    getDataTable();
  }, [product.productId]);

  useEffect(() => {
    console.log(listRandom);
  }, [listRandom]);

  const getDataTable = () => {
    setLoading(true);
    axiosWithHeaders("get", LIST_CUSTOMER_PRODUCT, {
      ProductId: product.productId,
      Turn,
    })
      .then((response: any) => {
        if (response.data && response.status === enums.STATUS_RESPONSE_OK) {
          const { status, message, result } = response.data;
          if (status === enums.STATUS_RESPONSE_OK) {
            let list = cloneDeep(result);
            list.forEach((item: any, index: number) => {
              item.no = index + 1;
            });

            setData(list);
          } else {
            alert(message);
          }
        }
      })
      .catch((error: any) => {
        alert(error.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHide = () => {
    if (loading) return;
    getDataTable();
    setVisible(false);
  };

  const onLucky = (e: any) => {
    e.preventDefault();
    // setVisible(true);
    setLoading(true);

    axiosWithHeaders("post", LUCKY_ANNAM, { ProductId: product.productId, Turn })
      .then((response: any) => {
        if (response.data && response.status === enums.STATUS_RESPONSE_OK) {
          console.log(response.data);
          const { status, message, result } = response.data;
          if (status === enums.STATUS_RESPONSE_OK) {
            setVisible(true);
            setListRandom(result);
          } else {
            alert(message);
          }
        }
      })
      .catch((error: any) => {
        alert(error.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RenderBody = () => {
    const { width, height } = dimension;
    let w1 = 0,
      h1 = 0,
      w2 = 0,
      h2 = 0,
      w3 = 0,
      h3 = 0,
      styleBackground = {},
      styleBackgroundContainer = {},
      styleCardContainer = {},
      styleTableOuterContainer = {},
      styleButton: any = {},
      styleModal = {},
      styleModalCloseButton = {},
      styleModalContainer = {},
      styleModalProduct = {},
      styleTableInnerContainer = {},
      styleTableHeader = {},
      // styleTableTotal = {},
      styleTableDescription = {},
      styleTableBody = {},
      stylePerson = {},
      styleLogo = {},
      styleTagLine = {},
      styleLabel = {},
      stylePrize1 = {},
      stylePrize2 = {},
      stylePrize3 = {},
      styleDropdown = {},
      stylePrizeDropdown = {},
      stylePrize1Dropdown = {},
      stylePrize2Dropdown = {},
      stylePrize3Dropdown = {},
      w4 = 0,
      h4 = 0,
      styleTableCandy = {},
      styleModalTitleLine1 = {},
      styleModalTitleLine2 = {};
    w1 = width;
    h1 = (w1 * 9) / 16;
    h2 = height;
    w2 = (h2 * 16) / 9;

    w3 = w1 > w2 ? w2 : w1;
    h3 = h1 > h2 ? h2 : h1;

    styleBackground = {
      width: `100%`,
      height: `100%`,
      overflow: `hidden`,
      position: `relative`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/background/background-ngay-quay-thuong.jpg)`,
      backgroundSize: `cover`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundBlendMode: `multiply`,
    };

    /*background*/
    let h11 = h3 * 1.225;

    /*sign*/
    let w12 = w3 * 0.12;
    let h12 = (w12 * 213) / 300;

    /*person*/
    let w13 = w3 * 0.5;
    let h13 = (w13 * 47) / 50;

    /*logo*/
    let w14 = w3 * 0.1575;
    let h14 = (w14 * 155) / 528;

    styleLogo = {
      width: `${w13 * 0.9}px`,
      height: `${h13 * 0.5}px`,
      position: `absolute`,
      top: `${0}px`,
      left: `${w14 * 0.185}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/tet-vui-trung-lon.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      zIndex: 10,
    };

    /*dropdown*/
    let w16 = w3 * 0.3;
    let h16 = h3 * 0.475;

    styleDropdown = {
      width: `${w16 * 1.5}px`,
      height: `${h16 * 1.3}px`,
      position: `absolute`,
      bottom: `${h12 * 0.6}px`,
      left: `${w16 * 0.025}px`,
      zIndex: 100,
    };

    let w17 = w3 * 0.285;
    let h17 = (w17 * 236) / 300;

    stylePrizeDropdown = {
      width: `${w17 * 1.5}px`,
      height: `${h17 * 1.5}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/giai-dac-biet.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      zIndex: 10,
    };

    stylePrize1Dropdown = {
      width: `${w17 * 1.5}px`,
      height: `${h17 * 1.5}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/giai-nhat.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      zIndex: 10,
    };

    stylePrize2Dropdown = {
      width: `${w17 * 1.5}px`,
      height: `${h17 * 1.5}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/giai-nhi.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      zIndex: 10,
    };

    stylePrize3Dropdown = {
      width: `${w17 * 1.5}px`,
      height: `${h17 * 1.5}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/giai-ba.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      zIndex: 10,
    };

    let w20 = w3 * 0.205;
    let h20 = (w20 * 236) / 300;

    stylePrize1 = {
      width: `${w13 * 0.9}px`,
      height: `${h13 * 0.5}px`,
      position: `absolute`,
      top: `${0}px`,
      right: `${w14 * 0.185}px`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/2024-giai-thuong.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
    };

    styleBackgroundContainer = {
      width: `${w3}px`,
      height: `${h3}px`,
      margin: `${height - h3 > 0 ? (height - h3) / 2 : 0}px ${
        width - w3 > 0 ? (width - w3) / 2 : 0
      }px`,
      backgroundImage: `
        url(${process.env.PUBLIC_URL}/assets/images/element/2-min.jpg), 
      `,
      backgroundSize: `
        ${w3}px ${h11}px,
        ${w12}px ${h12}px
      `,
      backgroundPosition: `
        ${0}px ${-h11 * 0.148}px,
        ${w12 * 1.17}px ${h12 * 1.26}px
      `,
      backgroundRepeat: `
        no-repeat,
        no-repeat
      `,
      position: `relative`,
      zIndex: 10,
    };

    styleCardContainer = {
      position: `absolute`,
      bottom: 0,
      left: 0,
      width: `100%`,
      height: h3 * 0.6425,
      zIndex: 30,
    };

    styleTableHeader = {
      color: `#E22124`,
      fontFamily: `Baloo2-bold`,
      fontSize: w3 * 0.02,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: h11 * 0.02,
    };

    styleTableDescription = {
      paddingTop: h3 * 0.025,
      height: h3 * 0.07,
      color: `#E22124`,
      fontFamily: `Montserrat`,
      fontSize: w3 * 0.015,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontWeight: 700,
    };

    styleTableBody = {
      padding: `${h3 * 0.02}px ${w3 * 0.045}px ${h3 * 0.01}px ${w3 * 0.033}px`,
      width: `100%`,
      fontFamily: `UTM-Swiss-CondensedBold`,
    };

    styleButton = {
      position: `absolute`,
      bottom: h3 * 0.035,
      left: w3 * 0.135,
      width: w3 * 0.175,
      height: w3 * 0.038,
      borderRadius: w3 * 0.015,
      fontSize: w3 * 0.018,
      fontFamily: `Baloo2-Bold`,
      zIndex: 999,
      padding: 0,
    };

    styleTableOuterContainer = {
      position: `absolute`,
      right: w3 * 0.017,
      bottom: 0,
      width: w3 * 0.58,
      height: h3 * 0.58725,
      zIndex: 999,
    };

    /*candy table*/
    w4 = w3 * 0.33;
    h4 = (w4 * 69) / 171;
    styleTableCandy = {
      position: `absolute`,
      width: w4,
      height: h4,
      top: h3 * 0.29,
      right: -w3 * 0.086,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundBlendMode: `multiply`,
      zIndex: 99,
      pointerEvents: `none`,
    };

    styleTableInnerContainer = {
      position: `absolute`,
      top: 1.5,
      left: 2,
      width: w3 * 0.5925 - 4,
      height: h3 * 0.58725 - 4,
      zIndex: 102,
    };

    styleModal = {
      width: "100%",
      height: "100%",
      position: `relative`,
      zIndex: 30,
      maxHeight: "100%",
    };

    styleModalCloseButton = {
      position: `absolute`,
      right: w3 * 0.01,
      top: h3 * 0.02,
      width: w3 * 0.0425,
      height: w3 * 0.0425,
      border: `unset`,
      backgroundColor: `transparent`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/close.png)`,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundBlendMode: `multiply`,
      zIndex: 999,
    };

    styleModalContainer = {
      width: "100%",
      height: "100%",
      // backgroundColor: `#fcf5a5`,
      backgroundSize: `cover`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      // backgroundBlendMode: `multiply`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/background/background-trang-ket-qua_ezl7bh_c_scale,w_1400.jpg)`,
      top: 2,
      left: 2,
      position: `absolute`,
      zIndex: 20,
      objectFit: "cover",
    };

    let w23 = w3 * 0.28;
    let w22 = w3 * 0.1475;

    styleLabel = {
      fontSize: `${w22 * 0.075}px`,
      fontFamily: `Montserrat`,
      textAlign: "center",
    };

    styleModalProduct = {
      position: `absolute`,
      width: w20 * 1.7,
      height: h20 * 1.7,
      top: h20 * 1.8,
      left: w3 * 0.04,
      transform: "translateY(-50%)",
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundBlendMode: `multiply`,
      zIndex: 99,
      pointerEvents: `none`,
    };

    styleModalTitleLine1 = {
      position: `absolute`,
      left: "50%",
      top: h3 * 0.07,
      color: `#E22124`,
      fontFamily: `Baloo2-bold`,
      fontSize: w3 * 0.04,
    };

    styleModalTitleLine2 = {
      position: `absolute`,
      top: h3 * 0.275,
      right: w3 * 0.075,
      color: `#E22124`,
      fontFamily: `Montserrat`,
      fontSize: w3 * 0.01525,
      width: "55%",
    };

    return (
      <>
        <div style={{ ...styleBackground }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/background/background-trang-ket-qua_ezl7bh_c_scale,w_1400.jpg`}
            alt="background-modal"
            style={{ display: "none" }}
          />
          <div id="background-container" style={{ ...styleBackgroundContainer }}>
            <div style={{ ...styleLogo }}></div>
            <div style={{ ...stylePrize1 }}></div>
            <div style={{ ...styleDropdown }}>
              <Dropdown
                value={product.productId}
                className="dropdown-lucky"
                options={listDropdown}
                onChange={(e) => {
                  const product = listDropdown.find((item) => item.productId === e.value);
                  setProduct(product as (typeof listDropdown)[number]);
                }}
                optionLabel="productName"
                placeholder="Chọn giải thưởng"
                optionValue="productId"
                style={{
                  width: `100%`,
                  height: `100%`,
                  borderColor: `transparent`,
                  backgroundColor: `transparent`,
                }}
                valueTemplate={(option: any) => {
                  switch (option.productId) {
                    case 5:
                      return (
                        <div
                          style={{
                            width: `100%`,
                            height: `100%`,
                            position: `relative`,
                          }}
                        >
                          <div style={{ ...stylePrizeDropdown }}></div>
                        </div>
                      );
                    case 4:
                      return (
                        <div
                          style={{
                            width: `100%`,
                            height: `100%`,
                            position: `relative`,
                          }}
                        >
                          <div style={{ ...stylePrize1Dropdown }}></div>
                        </div>
                      );
                    case 3:
                      return (
                        <div
                          style={{
                            width: `100%`,
                            height: `100%`,
                            position: `relative`,
                          }}
                        >
                          <div style={{ ...stylePrize2Dropdown }}></div>
                        </div>
                      );
                    case 2:
                      return (
                        <div
                          style={{
                            width: `100%`,
                            height: `100%`,
                            position: `relative`,
                          }}
                        >
                          <div style={{ ...stylePrize3Dropdown }}></div>
                        </div>
                      );
                    default:
                      return null;
                  }
                }}
              />
            </div>
            <div style={{ ...styleTableCandy }}></div>
            {loading ? (
              <div
                className="loading box-border-aff"
                style={{
                  ...styleButton,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/loading.svg`}
                  alt="loading"
                  width={50}
                  height={50}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/button/bg-button.png`}
                  alt="bg-buton"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    objectFit: "cover",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    borderRadius: styleButton.borderRadius,
                  }}
                />
              </div>
            ) : (
              <button
                className="box-border-aff"
                disabled={[1, 2].includes(Number(Turn))}
                style={{ ...styleButton }}
                onClick={onLucky}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/button/bg-button.png`}
                  alt="bg-buton"
                  style={{
                    display: [1, 2].includes(Number(Turn)) ? "none" : "block",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    objectFit: "cover",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    borderRadius: styleButton.borderRadius,
                  }}
                />
                QUAY THƯỞNG
              </button>
            )}

            <div id="card-container" style={{ ...styleCardContainer }}>
              <div
                style={{
                  width: `100%`,
                  height: `100%`,
                  position: `relative`,
                  zIndex: 999,
                }}
              >
                <div id="table-outer-container" style={{ ...styleTableOuterContainer }}>
                  <div
                    style={{
                      width: `100%`,
                      height: `100%`,
                      position: `relative`,
                      zIndex: 999,
                    }}
                  >
                    <div id="table-inner-container" style={{ ...styleTableInnerContainer }}>
                      <div
                        style={{
                          width: `100%`,
                          height: `100%`,
                          position: `relative`,
                          zIndex: 999,
                        }}
                      >
                        <div style={{ ...styleTableHeader }} className="text-shadow-white">
                          <span>DANH SÁCH CÁC MÃ TRÚNG THƯỞNG</span>
                          <span style={{ textTransform: "uppercase" }}>{product.productName}</span>
                        </div>
                        <div style={{ ...styleTableDescription }}>
                          ĐỢT QUAY LẦN {Turn}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NGÀY QUAY:{" "}
                          {drawDay[Number(Turn) - 1]}
                        </div>

                        <div style={{ ...styleTableBody }}>
                          <div style={{ width: `100%`, height: `100%` }} className="table-lucky">
                            <Table
                              columns={column}
                              rowKeyField="code"
                              // paging={{
                              //   enabled: product.productId === 4 ? false : true,
                              //   pageSize: 100,
                              // }}
                              loading={{
                                enabled: loading,
                              }}
                              data={data}
                              // dispatch={dispatch} // dispatch is required for obtain new actions from the UI
                              childComponents={{
                                tableWrapper: {
                                  elementAttributes: () => ({
                                    style: {
                                      maxHeight: h3 * 0.33,
                                      // borderLeft: "2px solid rgb(161 98 7)",
                                      border: "2px solid rgb(161 98 7)",
                                      // borderRight: "none",
                                      borderCollapse: "collapse",
                                      borderRadius: "12px",
                                      position: "relative",
                                      borderTopRightRadius: "12px",
                                    },
                                  }),
                                },
                                noDataRow: {
                                  content: () => {
                                    return (
                                      <div
                                        style={{
                                          width: `100%`,
                                          // height: 30,
                                          textAlign: `center`,
                                          display: `flex`,
                                          alignItems: `center`,
                                          justifyContent: `center`,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: `${h3 * 0.025}px`,
                                            color: `#E22124`,
                                            fontWeight: 700,
                                          }}
                                        >
                                          Chưa có dữ liệu
                                        </span>
                                      </div>
                                    );
                                  },
                                },
                                headCell: {
                                  content: (props) => {
                                    return (
                                      <div
                                        style={{
                                          width: `100%`,
                                          height: `100%`,
                                          textAlign: `center`,
                                          paddingTop: h20 * 0.03,
                                          paddingBottom: h20 * 0.03,
                                          borderBottom: "2px solid rgb(161 98 7)",
                                          // borderTop: "2px solid rgb(161 98 7)",
                                          backgroundImage:
                                            "linear-gradient(to right, #fde047,#fef08a,#fde047 )",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: `${h3 * 0.025}px`,
                                            color: `#E22124`,
                                            fontWeight: 700,
                                            fontFamily: "Baloo2-bold",
                                          }}
                                        >
                                          {props.column.title}
                                        </span>
                                      </div>
                                    );
                                  },
                                },
                                cellText: {
                                  content: (props) => {
                                    return (
                                      <div
                                        style={{
                                          width: `100%`,
                                          height: `100`,
                                          textAlign: `center`,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: `${h3 * 0.025}px`,
                                            color:
                                              props.column.field === "code" ? "#E22124" : "#000",
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                          }}
                                        >
                                          {props.value || "Đang được xác nhận"}
                                        </span>
                                      </div>
                                    );
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          className="modal-lucky"
          style={{ ...styleModal }}
          focusOnShow={false}
          showHeader={false}
          visible={visible}
          // onHide={() => {
          //   if (!loading) setVisible(false);
          // }}
        >
          <div
            style={{
              ...styleModalProduct,
              width:
                product.productId === 5 ? w1 * 0.5 : product.productId === 4 ? w1 * 0.5 : w20 * 1.2,
              height:
                product.productId === 5
                  ? (w1 * 0.5 * 12) / 16
                  : product.productId === 4
                  ? (w1 * 0.5 * 12) / 16
                  : h20 * 1.3,
              top:
                product.productId === 5 ? h20 * 2 : product.productId === 4 ? h20 * 2 : h20 * 0.7,
              left:
                product.productId === 5
                  ? w20 * 0.14
                  : product.productId === 4
                  ? w20 * 0.1
                  : w3 * 0.001,
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/element/giai-${
                product.productId === 5
                  ? "dac-biet"
                  : product.productId === 4
                  ? "nhat"
                  : product.productId === 3
                  ? "nhi"
                  : "ba"
              }.png)`,
            }}
          >
            {/* <span
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, 100%)",
                whiteSpace: "nowrap",
                ...styleLabel,
                fontSize: `${w22 * 0.065}px`,
                color: "#000",
                marginTop: `${w22 * 0.065}px`,
              }}
            >
              Hình ảnh giải thưởng mang tính chất minh họa
            </span> */}
          </div>
          <div style={{ ...styleModalContainer }}>
            <RenderDelayWrapper
              delay={
                product.productId === 5
                  ? 10000 * 1 + 2000
                  : product.productId === 4
                  ? 10000 * 1 + 2000
                  : 15000 + 2000
              }
            >
              <button style={{ ...styleModalCloseButton }} onClick={onHide}></button>
            </RenderDelayWrapper>
            <div style={{ width: `100%`, height: `100%`, position: `relative` }}>
              <div
                style={{
                  ...styleModalTitleLine1,
                  transform: "translateX(-50%)",
                  whiteSpace: "nowrap",
                  top: product.productId === 5 || product.productId === 4 ? h3 * 0.07 : h3 * 0.12,
                }}
                className="text-shadow-white"
              >
                CHÚC MỪNG MÃ SỐ DỰ THƯỞNG
              </div>
              <div
                style={{
                  ...styleModalTitleLine2,
                  top:
                    product.productId === 5
                      ? h3 * 0.35
                      : product.productId === 4
                      ? h3 * 0.2
                      : h3 * 0.3,
                  textTransform: "uppercase",
                  fontWeight: 700,
                  width: product.productId === 5 || product.productId === 4 ? w1 * 0.55 : w1 * 0.94,
                  right:
                    product.productId === 5 || product.productId === 4 ? w3 * 0.025 : w3 * 0.04,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: product.productId === 5 ? "20px" : "0px",
                  }}
                >
                  ĐÃ TRÚNG : {product.productName}
                  <br />
                  <div
                    className="text-shadow-white"
                    style={{
                      fontFamily: "Baloo2-Bold",
                      fontSize: w23 * 0.07,
                      marginTop: 10,
                    }}
                  >
                    {product.prize}
                  </div>
                </div>

                {product.productId === 5 || product.productId === 4 ? (
                  <div
                    style={{
                      width: `100%`,
                      height: `100%`,
                      position: `relative`,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <RenderDelayWrapper delay={1000}>
                      {listRandom?.map(({ code }, codeIndex) => (
                        <div
                          key={code}
                          className="fade-in"
                          style={{
                            display: "flex",
                            margin: product.productId === 5 ? `${h20 * 0.05}px 0px` : "1px 0px",
                            justifyContent: "center",
                          }}
                        >
                          {code.split("").map((result, charIndex) => {
                            return (
                              <div
                                key={code + result + charIndex}
                                style={{
                                  border: "2px solid rgb(161 98 7)",
                                  minWidth: w20 * 0.3,
                                  height: product.productId === 5 ? h20 * 0.35 : h20 * 0.3,
                                  background: "linear-gradient(to bottom, #fde047,#fef08a)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "4px",
                                  marginTop: "2px",
                                  fontSize: product.productId === 5 ? w20 * 0.24 : w20 * 0.21,
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <RenderDelayWrapper delay={0}>
                                  <RandomText
                                    length={1}
                                    result={result}
                                    delay={1000 * (charIndex + 4)}
                                  />
                                </RenderDelayWrapper>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </RenderDelayWrapper>
                  </div>
                ) : (
                  <div
                    style={{
                      width: `100%`,
                      height: `100%`,
                      position: `relative`,
                      display: "grid",
                      gridTemplateColumns: "repeat(10, minmax(0, 1fr))",
                      gap: 10,
                    }}
                  >
                    <RenderDelayWrapper delay={0}>
                      {listRandom.map((item, codeIndex) => (
                        <div
                          key={item.code}
                          className="fade-in"
                          style={{
                            border: "2px solid rgb(161 98 7)",
                            width: "100%",
                            height: h20 * 0.3,
                            background: "linear-gradient(to bottom, #fde047,#fef08a)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "4px",
                            fontSize: w20 * 0.075,
                            overflow: "hidden",
                            position: "relative",
                            // animationDuration: "0.3s",
                          }}
                        >
                          <div style={{ position: "absolute", top: 0, visibility: "hidden" }}>
                            {item.code}
                          </div>
                          <div
                            style={{
                              // overflow: "hidden",
                              position: "relative",
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              paddingLeft: w20 * 0.04,
                              paddingRight: w20 * 0.04,
                            }}
                          >
                            {item.code.split("").map((char, index) => (
                              <div
                                style={{
                                  // border: "2px solid rgb(161 98 7)",
                                  // minWidth: w20 * 0.057,
                                  width: w20 * 0.057,
                                  height: h20 * 0.1,
                                  // background: "linear-gradient(to bottom, #fde047,#fef08a)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px",
                                  fontSize: w20 * 0.075,
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                                key={item.code + char + index}
                              >
                                <RandomText
                                  length={1}
                                  result={char}
                                  delay={2000 * (index + 1) + 1000}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </RenderDelayWrapper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <main
      style={{
        width: `100%`,
        height: `100%`,
        minHeight: `100vh`,
        position: `relative`,
      }}
    >
      <RenderBody />
    </main>
  );
}

const mapStateToProps = (state) => {
  // console.log(state.datadefault.listCost);
  return {
    profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    error_success: (severity, summary, detail) =>
      dispatch(actions.error_success(severity, summary, detail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lucky));
