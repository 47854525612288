import * as types from '../contains/actionTypes';

export const breadcrumb_ini = () => {
    return {
        type: types.BREADCRUMB_INI
    };
};

export const breadcrumb_change = (title: string, href: string, icon: string, description: string, menuIndex: number, parentIndex: number, parentName: string) => {
    return {
        type: types.BREADCRUMB_CHANGE,
        title,
        href,
        icon,
        description,
        menuIndex,
        parentIndex,
        parentName
    }
}

export function saveBreadcrumb(title: string, href: string, icon: string, description: string, menuIndex: number, parentIndex: number, parentName: string){
    let breadcrumb = {
        title,
        href,
        icon,
        description,
        menuIndex,
        parentIndex,
        parentName
    }

    // console.log(breadcrumb);
    localStorage.setItem(types.LOCAL_STORAGE_BREADCRUMB, JSON.stringify(breadcrumb));
}

