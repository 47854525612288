import React from "react";
import { connect } from 'react-redux';
import * as enums from "../../common/enum";
import { axiosWithHeaders } from "../../reducer/contains/axiosWrapper";
import {
    RESET_LUCKY,
    FINISH_LUCKY
} from "../../reducer/contains/api";
import * as actions from '../../reducer/actions/index';
import {
    withRouter
} from "react-router-dom";
import Swal from "sweetalert2";
const ResetLucky = (props: any) => {
    const{profile} = props
    React.useEffect(() => {
         if( ![1,2].includes(profile.id)){
             props.history.push("/");
         }
      }, []);
    const onFinish = (Turn:number) => {
        Swal.fire({
            title: 'Thông báo',
            text: `Bạn có muốn xác nhận chốt danh sách quay thưởng đợt ${Turn} không?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders("get", `${FINISH_LUCKY}${Turn}`, undefined)
                .then((response: any) => {
                    if(response.data && response.status === enums.STATUS_RESPONSE_OK){
                        const {
                            status,
                            message,
                        } = response.data;
                        if(status === enums.STATUS_RESPONSE_OK){
                            alert(`CHỐT thành công`);
                        }
                        else{
                            alert(message);
                        }
                    }
                })
                .catch((error) => {
                    alert(error.toString());
                });
            }
        })

       
    }

    const renderFinish=()=>{
        // if( [1,2,8].includes(profile.id))
        // {
            return <>
                <button className="learn-more learn-more-finish" onClick={()=>onFinish(3)}>CHỐT quay số Đợt 3 </button>
            </>
        // }
        // return null
    }
    return (
        <div>
            {renderFinish()}
        </div>
    );
}

const mapStateToProps = state => {
    // console.log(state.datadefault.listCost);
    return {
        profile: state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        error_success: (severity,summary,detail) => dispatch(actions.error_success(severity,summary,detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetLucky));