import * as api from "../../../reducer/contains/api";

export const listColumnUser = [
  {
    field: "stt",
    title: "#",
    width: 60,
  },
  {
    field: "username",
    title: "Tài khoản",
    width: 250,
  }, 
  {
    field: "name",
    title: "Nhân viên",
    width: 250,    
  },

  {
    field: "roleName",
    title: "Quyền sử dụng",
    width: 250,    
  },
  {
    field: "action",
    headerStyle: {
      textAlign: `center`,
    },
    style: {
      justifyContent: `center`,
    },
    // title: "Ngày kết thúc",
    width: 80,
  },
];

export const configGridUser = {
  useDateRange: false,
  allowAdd: true,
  titleAdd: "Thêm",
  api: api.LIST_USER,
  // useColNo: true,
  defaultFilter: {
    search: "",
  },
};
